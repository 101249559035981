// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, Tabs, Button } from "antd";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      isNew,
      name,
      title,
      email,
      phone,
      onChangeName,
      onChangeEmail,
      onChangeTitle,
      onChangePhone,
    } = this.props;

    return (
      <Tabs defaultActiveKey={"1"}>
        <TabPane tab="Основные данные" key="1">
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Имя"}
                name="name"
                initialValue={name}
                onChange={onChangeName}
                rules={[{ required: true, message: "Введите имя!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Email"}
                name="email"
                initialValue={email}
                onChange={onChangeEmail}
                rules={[{ required: true, message: "Введите email!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Должность"}
                name="title"
                initialValue={title}
                onChange={onChangeTitle}
                rules={[{ required: true, message: "Введите должность!" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <Form.Item
                label={"Телефон"}
                name="phone"
                initialValue={phone}
                onChange={onChangePhone}
                rules={[{ required: true, message: "Введите телефон!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </TabPane>
      </Tabs>
    );
  }
}

Header.propTypes = {};

export default Header;
