// @flow
import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Actions from "../../actions/session";

const FormItem = Form.Item;

class Confirm extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  onFinish = (values) => {
    const token = this.props.match.params.token;
    const {
      history: { push },
    } = this.props;
    this.props.dispatch(Actions.confirm(values, token, push));
  };

  render() {
    const { submitting } = this.props;
    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Row gutter={16} type="flex" justify="center">
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h2 style={{ textAlign: "center" }}>Задайте пароль</h2>
            </Col>
          </Row>
          <Row gutter={16}>
            <Form onFinish={this.onFinish}>
              <Row gutter={16}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: "Введите пароль!" }]}
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="пароль"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item
                    name="password_confirmation"
                    rules={[
                      { required: true, message: "Введите пароль еще раз!" },
                    ]}
                  >
                    <Input
                      prefix={
                        <LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      type="password"
                      placeholder="подтверждение пароля"
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <FormItem>
                    <Button
                      type="submit"
                      disabled={submitting}
                      htmlType="submit"
                      className="login-form-button"
                    >
                      {submitting ? "Сохранение..." : "Сохранить"}
                    </Button>
                  </FormItem>
                </Col>
              </Row>
            </Form>
          </Row>
        </div>
      </div>
    );
  }
}

Confirm.propTypes = {
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => ({
  errors: state.session.errors,
});

export default connect(mapStateToProps)(withRouter(Confirm));
