import { combineReducers } from "redux";
import session from "./session";
import dashboard from "./dashboard";
import currentEmployee from "./currentEmployee";
import employees from "./employees";
import regions from "./regions";
import cities from "./cities";
import faqs from './faqs';
import organisations from "./organisations";
import tickets from './tickets';
import entityTasks from './entityTasks';
import orders from './orders';
import orderTasks from './orderTasks';
import tasks from './tasks';
import entities from './entities';

const appReducer = combineReducers({
  session,
  dashboard,
  currentEmployee,
  employees,
  regions,
  cities,
  faqs,
  organisations,
  tickets,
  entityTasks,
  orders,
  orderTasks,
  tasks,
  entities
});

export default function (state, action) {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
