import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from './reportWebVitals';
import store from "./store";
import "./style/antd.less";
import "./style/index.css";
import App from "./containers/App";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import { ConfigProvider } from 'antd';
import ru_RU from "antd/lib/locale-provider/ru_RU";
import moment from "moment";
moment.locale("ru");



ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ru_RU}>
      <App history={createBrowserHistory} />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
