import update from "immutability-helper";

const initialState = {
  regions: [],
  region: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_REGIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REGIONS":
      return {
        ...state,
        regions: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_REGION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REGION":
      return {
        ...state,
        region: action.response.data,
        isLoading: false,
      };

    

    case "REQUEST_REGION_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_REGION_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_REGION_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REGIONS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    
    default:
      return state;
  }
}
