import api from "../api";
const Actions = {};

// calendar
Actions.fetchCalendarEvents = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CALENDAR_EVENTS_REQUEST" });
    const params = getState().dashboard.fetchParams;
    api
      .fetch("/dashboard/calendar_events", params)
      .then((response) => {
        dispatch({ type: "FETCH_CALENDAR_EVENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CALENDAR_EVENTS_FAILURE", response });
      });
  };
};

Actions.fetchEvent = (id, eventType) => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_EVENT_REQUEST" });
    api
      .fetch(`/${eventType}/${id}/event`)
      .then((response) => {
        dispatch({ type: "FETCH_EVENT", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_EVENT_FAILURE", response });
      });
  };
};

Actions.onChangeDate = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_DATE",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeEventType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_EVENT_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

// statistic
Actions.fetchTasks = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_TASKS_REQUEST" });
    const params = getState().dashboard.fetchTasksParams;
    return api
      .fetch("/dashboard/tasks", params)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_TASKS_FAILURE", response });
      });
  };
};

Actions.fetchStatistic = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_DASHBOARD_STATISTIC_REQUEST" });
    const params = getState().dashboard.fetchStatisticParams;

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
    };

    return api
      .fetch("/dashboard/statistic", data)
      .then((response) => {
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_DASHBOARD_STATISTIC_FAILURE", response });
      });
  };
};

Actions.onChangeStatisticDateRange = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_STATISTIC_DATE_RANGE",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeTasksAssigneeType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_CHANGE_TASKS_ASSIGNEE_TYPE",
      value: value,
    });
    return Promise.resolve();
  };
};

export default Actions;
