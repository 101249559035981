// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import { Row, Col, Form, Icon, Input, Button } from "antd";
import { UserOutlined } from "@ant-design/icons";

const FormItem = Form.Item;

class RecoveryForm extends Component {
  onFinish = (values) => {
    // console.log(values);
    this.props.onSubmit(values);
  };

  render() {
    const { submitting, isRecovered } = this.props;

    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <h2 style={{ textAlign: "center" }}>Восстановление пароля</h2>
            </Col>
          </Row>
          {isRecovered ? (
            <Row>
              <Col
                xs={24}
                sm={24}
                md={24}
                lg={24}
                xl={24}
                style={{ textAlign: "center" }}
              >
                <span>Запрос на восстановление успешно отправлен!</span>
                <span>Следуйте инструкциям, отправленным вам на email.</span>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <Form onFinish={this.onFinish}>
                  <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.Item
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: "Пожалуйста, введите логин!",
                          },
                        ]}
                      >
                        <Input
                          prefix={<UserOutlined />}
                          placeholder="ваш email"
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                      <FormItem>
                        <Button
                          type="submit"
                          disabled={submitting}
                          htmlType="submit"
                          className="login-form-button"
                        >
                          {submitting ? "Отправка..." : "Отправить"}
                        </Button>
                      </FormItem>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          )}

          <Row type="flex" justify="center">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <span>
                <Link to={`/auth/login`}>Ввести пароль</Link>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default RecoveryForm;
