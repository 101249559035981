// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Descriptions, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

import Assignees from "../../../components/Assignees";
import Documents from "../../../components/Documents";
import User from "../../../components/User";
import Member from "../../../components/Member";

class Statuses extends Component {
  render() {
    const {
      orderTask,
      currentEmployee,
      user,
      member,
      onChangeStatus,
      // assignees
      isAssigneesLoading,
      isOrganisation,
      customer,
      company,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      onDeleteAssignee,
      onCreateAssignee,
      // attachments
      attachments,
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Статус"}
              name="status"
              initialValue={orderTask.status ? orderTask.status : null}
              onChange={onChangeStatus}
            >
              <SelectFetch
                showSearch
                placeholder="Статус"
                onChange={onChangeStatus}
                selected={orderTask.status ? orderTask.status : null}
                url={`/order_tasks/${orderTask.id}/statuses`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions column={1} bordered size="small">
              <Descriptions.Item label="Плановые дата и время" span={3}>
                {orderTask.due_by
                  ? moment(orderTask.due_by).format("lll")
                  : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              customer={customer}
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От подрядчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="company"
              company={company}
              assignees={companyAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
        <Col span={24}>
          <Documents
            isReadOnly={false}
            currentUser={currentEmployee}
            isUploading={isUploading}
            attachments={attachments}
            onUploadAttachment={onUploadAttachment}
            onDeleteAttachment={onDeleteAttachment}
          />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {};

export default Statuses;
