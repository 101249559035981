// @flow
import React, { Component } from "react";
import { Row, Col, Form, Card, Tabs, Button, Space } from "antd";
import PropTypes from "prop-types";
import General from "./General";
import Documents from "../Documents";
import Statuses from "./Statuses";
import EntityServices from "./EntityServices";
import OpeningHours from "./OpeningHours";
import Notes from "./Notes";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class EntityForm extends Component {
  render() {
    const {
      currentEmployee,
      isLoading,
      entity,
      onClose,
      // attachments
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
    } = this.props;

    const organisationAssignees = entity.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = entity.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const customerAssignees = entity.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = entity.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const internalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "internal";
    });

    const externalAttachments = entity.attachments.filter(function (item) {
      return item.storage_type == "external";
    });

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>{`Объект #${entity.name}`}</h2>
          </div>
        }
        extra={[
          <Button key={1} onClick={onClose}>
            Закрыть
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <General entity={entity} />

              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Разделы" key="1">
                  <EntityServices
                    entity={entity}
                    entityServices={entity.entity_services}
                  />
                </TabPane>
                <TabPane tab="Расписание" key="2">
                  <OpeningHours
                    openingHours={entity.opening_hours}
                  />
                </TabPane>
                <TabPane tab="Файлы" key="3">
                  <Documents
                    currentEmployee={currentEmployee}
                    title={""}
                    isUploading={isUploading}
                    attachments={internalAttachments}
                    onUpload={onUploadAttachment}
                    onDelete={onDeleteAttachment}
                  />
                </TabPane>
                <TabPane tab="Примечания" key="4">
                  <Notes
                    currentEmployee={currentEmployee}
                    notes={entity.entity_notes}
                  />
                </TabPane>
              </Tabs>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Statuses
                customer={entity.customer}
                // assignees
                organisationAssignees={organisationAssignees}
                supervisorAssignees={supervisorAssignees}
                customerAssignees={customerAssignees}
                isAssigneesLoading={isAssigneesLoading}
                onDeleteAssignee={onDeleteAssignee}
                onCreateAssignee={onCreateAssignee}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

EntityForm.propTypes = {
  projectType: PropTypes.object,
};

export default EntityForm;
