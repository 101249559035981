// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class CustomersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      entities,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterRegions,
      onChangeFilterEntityType,
      onChangeFilterActive,
    } = this.props;

    const columns = [
      {
        title: "Дата принятия",
        dataIndex: "start",
        render: (start) => moment(start).format("L"),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "20%",
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/entities/${record.id}/edit`}>{record.name}</Link>
        ),
        align: "center",
        width: "35%",
      },
      {
        title: "Адрес",
        dataIndex: "address",
        align: "center",
        width: "45%",
      },
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.active ||
          fetchParams.entityType ||
          fetchParams.users ||
          fetchParams.members ||
          fetchParams.regions
            ? "primary"
            : "ghost"
        }
        key="filterButton"
        icon={<FilterOutlined />}
        onClick={this.onFilterToggle}
      />
    );

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        style={{ marginRight: 10 }}
        onClick={this.props.onFaqToggle}
      />
    );

    let mapButton = (
      <Link
        key="101"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/entities/map` }}
      >
        Карта
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Объекты`}
        extra={[
          // mapButton,
          // faqButton,
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={entities}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          users={fetchParams.users}
          members={fetchParams.members}
          regions={fetchParams.regions}
          entityType={fetchParams.entityType}
          active={fetchParams.active}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeRegions={onChangeFilterRegions}
          onChangeEntityType={onChangeFilterEntityType}
          onChangeActive={onChangeFilterActive}
        />
      </PageHeader>
    );
  }
}

CustomersList.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default CustomersList;
