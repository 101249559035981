import api from "../api";

const Actions = {};

Actions.fetchEmployees = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_EMPLOYEES_REQUEST" });
    const params = getState().employees.fetchParams;
    api
      .fetch("/employees", params)
      .then(response => {
        dispatch({ type: "FETCH_EMPLOYEES", response });
      })
      .catch(error => {
        if (error.response.status == 500) {
          error.response.data.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_EMPLOYEES_FAILURE", error });
      });
  };
};

Actions.fetchEmployee = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_EMPLOYEE_REQUEST" });
    api
      .fetch(`/employees/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_EMPLOYEE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_EMPLOYEE_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_NEW"
    });
  };
};

Actions.onUpdate = (id, avatar) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_EMPLOYEE_REQUEST" });
    const employee = getState().employees.employee;
    const data = {
      employee: {
        is_active: employee.is_active,
        role: employee.role,
        title: employee.title,
        email: employee.email,
        phone: employee.phone,
        name: employee.name,
        avatar: avatar ? avatar.attachment : null
      }
    };
    api
      .patch(`/employees/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_EMPLOYEE", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_EMPLOYEE_FAILURE", response });
      });
  };
};


Actions.onCreate = (avatar) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_EMPLOYEE_REQUEST" });
    const employee = getState().employees.employee;
    const data = {
      employee: {
        is_active: employee.is_active,
        role: employee.role,
        title: employee.title,
        email: employee.email,
        phone: employee.phone,
        name: employee.name,
        avatar: avatar ? avatar.attachment : null
      }
    };
    return api
      .post(`/employees`, data)
      .then(response => {
        dispatch({ type: "POST_EMPLOYEE", response });
        api.openNotification(
          "success",
          "Пользователь системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь системы", error);
          });
        }
        dispatch({ type: "POST_EMPLOYEE_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_EMPLOYEE_REQUEST" });
    return api
      .delete(`/employees/${id}`)
      .then(response => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_EMPLOYEE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_EMPLOYEE_FAILURE"
        });
      });
  };
};

Actions.onUploadAvatar = (id, file) => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_AVATAR_UPLOAD"
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/employees/${id}/avatar`, formData)
      .then(response => {
        api.openNotification(
          "success",
          "Пользователь",
          "Аватар успешно загружен!"
        );
        dispatch({ type: "EMPLOYEE_AVATAR_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "EMPLOYEE_AVATAR_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_EMPLOYEE_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_EMPLOYEE_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_EMPLOYEE_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_EMPLOYEES_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEES_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_ACTIVE",
      value: value
    });
  };
};


Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeEmail = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeTitle = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_TITLE",
      value: value
    });
  };
};

Actions.onChangePhone = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_PHONE",
      value: value
    });
  };
};

Actions.onChangePassword = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "EMPLOYEE_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};


export default Actions;
