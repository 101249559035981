// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./login.css";
import { Row, Col, Form, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./login.css";

class LoginForm extends Component {
  onFinish = (values) => {
    // console.log(values);
    this.props.onSubmit(values);
  };

  render() {
    const { submitting } = this.props;

    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Row>
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <h2 style={{ textAlign: "center" }}>Вход</h2>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form className="login-form" onFinish={this.onFinish}>
                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста, введите логин!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<UserOutlined />}
                        placeholder="пользователь"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "Пожалуйста, введите пароль!",
                        },
                      ]}
                    >
                      <Input
                        prefix={<LockOutlined />}
                        type="password"
                        placeholder="пароль"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.Item>
                    <Button
                      typeof="submit"
                      disabled={submitting}
                      htmlType="submit"
                      className="login-form-button"
                    >
                      {submitting ? "Вход..." : "Войти"}
                    </Button>
                  </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ textAlign: "center" }}
            >
              <span>
                <Link to={`/auth/recovery`}>Забыли пароль?</Link>
              </span>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default LoginForm;
