// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Comment, Tooltip, Avatar } from "antd";
import { Row, Col, Form, Input, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

const { TextArea } = Input;

class Notes extends Component {
  
  render() {
    const { notes } = this.props;
    

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }} style={{ marginBottom: "10px" }}>
        <Col span={24}>
          {notes.map(item => {
            return (
              <Comment
                key={item.id}
                author={item.user ? item.user.name : item.member.name}
                avatar={<Avatar src={item.user ? item.user.avatar : item.member.avatar} alt="" />}
                content={<p>{item.note}</p>}
                datetime={
                  <Tooltip
                    title={moment(item.created_at).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )}
                  >
                    <span>{moment(item.created_at).fromNow()}</span>
                  </Tooltip>
                }
              />
            );
          })}
        </Col>
        
      </Row>
    );
  }
}

Notes.propTypes = {
  notes: PropTypes.arrayOf(PropTypes.object)
};
export default Notes;
