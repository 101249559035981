// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/tickets";
import List from "./List";

class Tickets extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onSetFilters()).then(() => {
      this.props.dispatch(Actions.fetchTickets());
     
    });
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchTickets());
      });
    });
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchTickets()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };


  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      localStorage.setItem(
        "ticketsFilters",
        JSON.stringify(this.props.fetchParams)
      );
    });
  };

  onChangeFilterPeriod = (value, dateString) => {
    this.props
      .dispatch(Actions.onChangeFilterPeriod(dateString[0], dateString[1]))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterFinishPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterFinishPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterCreatedPeriod = (value, dateString) => {
    this.props
      .dispatch(
        Actions.onChangeFilterCreatedPeriod(dateString[0], dateString[1])
      )
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };
  
  onChangeFilterStatus = (status) => {
    this.props
      .dispatch(Actions.onChangeFilterStatus(status))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  onChangeFilterEmployees = (values) => {
    this.props
      .dispatch(Actions.onChangeFilterEmployees(values))
      .then(() => this.props.dispatch(Actions.fetchTickets()))
      .then(() => {
        localStorage.setItem(
          "ticketsFilters",
          JSON.stringify(this.props.fetchParams)
        );
      });
  };

  render() {
    const { isLoading, tickets, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        tickets={tickets}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
         // filters
         onClearFilters={this.onClearFilters}
         onChangeFilterPeriod={this.onChangeFilterPeriod}
         onChangeFilterFinishPeriod={this.onChangeFilterFinishPeriod}
         onChangeFilterCreatedPeriod={this.onChangeFilterCreatedPeriod}
         onChangeFilterStatus={this.onChangeFilterStatus}
         onChangeFilterEmployees={this.onChangeFilterEmployees}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.tickets.isLoading,
  tickets: state.tickets.tickets,
  meta: state.tickets.meta,
  fetchParams: state.tickets.fetchParams
});

export default connect(mapStateToProps)(Tickets);
