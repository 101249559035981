import update from "immutability-helper";

const initialState = {
  currentEmployee: {},
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_CURRENT_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CURRENT_ADMIN":
      return {
        ...state,
        currentEmployee: action.response.data,
        isLoading: false,
      };
    case "PATCH_CURRENT_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_CURRENT_ADMIN":
      return {
        ...state,
        currentEmployee: action.response.data,
        isLoading: false,
      };

    case "PATCH_CURRENT_ADMIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };
    case "PATCH_PASSWORD_CURRENT_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "PATCH_PASSWORD_CURRENT_ADMIN":
      return {
        ...state,
        isLoading: false,
      };
    case "PATCH_PASSWORD_CURRENT_ADMIN_FAILURE":
      return {
        ...state,
        isLoading: false,
      };
    case "CURRENT_ADMIN_CHANGE_NAME":
      return update(state, {
        currentEmployee: { first_name: { $set: action.value } },
      });

    case "CURRENT_ADMIN_CHANGE_MIDDLE_NAME":
      return update(state, {
        currentEmployee: { middle_name: { $set: action.value } },
      });

    case "CURRENT_ADMIN_CHANGE_LAST_NAME":
      return update(state, {
        currentEmployee: { last_name: { $set: action.value } },
      });
    case "CURRENT_ADMIN_CHANGE_EMAIL":
      return update(state, {
        currentEmployee: { email: { $set: action.value } },
      });

    case "CURRENT_ADMIN_CHANGE_PASSWORD":
      return update(state, {
        currentEmployee: { password: { $set: action.value } },
      });

    case "CURRENT_ADMIN_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        currentEmployee: { password_confirmation: { $set: action.value } },
      });


    default:
      return state;
  }
}
