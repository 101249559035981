// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Row, Button, Table } from "antd";
import _ from "lodash/array";
import moment from "moment";
import "moment/locale/ru";

class OpeningHours extends Component {
  render() {
    const { openingHours } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "day",
        render: (day) => moment().isoWeekday(day).format("dddd"),
        key: "name",
      },
      {
        title: "C",
        dataIndex: "opens",
        key: "opens",
      },
      {
        title: "По",
        dataIndex: "closes",
        key: "closes",
      },
    ];

    return (
      <React.Fragment>
        <Row gutter={16} style={{ paddingBottom: 10 }}>
          <Col span={24}>
            <Table
              size={"small"}
              showHeader={false}
              pagination={false}
              dataSource={openingHours}
              columns={columns}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

OpeningHours.propTypes = {
  openingHours: PropTypes.arrayOf(PropTypes.object),
};

export default OpeningHours;
