// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Pagination,
  Row,
  Col,
  Input,
  Badge,
  Typography,
  Tag,
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import OrderProgress from "../../../components/OrderProgress";
import moment from "moment";
import "moment/locale/ru";

import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "processing";
  }
}

function serviceTag(service) {
  return (
    <Tag key={service.id} color="#108ee9">
      {service.name}
    </Tag>
  );
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderProgress: false,
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      orders,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriodCreated,
      onChangeFilterPeriodFinish,
      onChangeFilterUsers,
      onChangeFilterEmployees,
    } = this.props;

    const columns = [
      {
        title: "Выполнение",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <OrderProgress orderId={record.id} progress={record.progress} />
            </span>
            <span style={{ opacity: "0.7" }}>{record.status_locale}</span>
          </div>
        ),
        key: "status",
        align: "center",
        width: "10%",
      },
      {
        title: "Номер / Номер КП",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/orders/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>{record.number_int}</span>
          </div>
        ),
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => (
          <span style={{ fontSize: 12 }}>{moment(created_at).format("L")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "created_at",
        align: "center",
        width: "10%",
      },
      {
        title: "Дата принятия",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{ fontSize: 12 }}>{moment(finish).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {<Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>}
            </span>
            <span style={{ opacity: "0.7" }}>{entity.address}</span>
          </div>
        ),
        align: "left",
        width: "20%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Paragraph ellipsis={{ rows: 3, expandable: true }}></Paragraph>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {serviceTag(record.entity_service)}
              {/* {record.entity_service ? record.entity_service.name : null} */}
            </span>
          </div>
        ),
        align: "left",
        width: "25%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.startCreatedDate ||
          fetchParams.starFinishDate ||
          fetchParams.status ||
          fetchParams.users ||
          fetchParams.employees
            ? "primary"
            : "ghost"
        }
        key="filterButton"
        icon={<FilterOutlined />}
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={"Заказы"}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={orders}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          starFinishDate={fetchParams.starFinishDate}
          endFinishDate={fetchParams.endFinishDate}
          users={fetchParams.users}
          employees={fetchParams.employees}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriodCreated={onChangeFilterPeriodCreated}
          onChangePeriodFinish={onChangeFilterPeriodFinish}
          onChangeUsers={onChangeFilterUsers}
          onChangeEmployees={onChangeFilterEmployees}
        />
      </PageHeader>
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default List;
