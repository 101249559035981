// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Descriptions } from "antd";

class General extends Component {
  render() {
    const { ticket } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions column={1} bordered>
              <Descriptions.Item label="Объект">
                {ticket.entity ? ticket.entity.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес">
                {ticket.entity ? ticket.entity.address : null}
              </Descriptions.Item>
              <Descriptions.Item label="Примечания" >
                {ticket.entity_notes}
              </Descriptions.Item>
              <Descriptions.Item label="Раздел">
                {ticket.category ? ticket.category.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Инциденты">
                {ticket.services.length > 0
                  ? ticket.services
                      .map((item) => {
                        return item.name;
                      })
                      .join(", ")
                  : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тема">
                {ticket.subject ? ticket.subject : null}
              </Descriptions.Item>
              <Descriptions.Item label="Описание">
                {ticket.description ? ticket.description : null}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
