// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Tabs, Button, Tooltip } from "antd";
import EntityTaskItems from "./EntityTaskItems";
import Todos from "./Todos";

const { TabPane } = Tabs;

class TasksTodos extends Component {
  render() {
    const {
      isTodoLoading,
      entityTaskItems,
      entityTaskTodos,
      onEntityTaskTodoSaveComment,
      onEntityTaskTodoCompleted
    } = this.props;

    let todos = [];
    let completedTodos = [];

    entityTaskTodos.map((element) => {
      element.entity_task_todo_items.map((item) => {
        todos.push(item);
      });
    });

    entityTaskTodos.map((element) => {
      element.entity_task_todo_items
        .filter((todoTtem) => todoTtem.completed_at != null)
        .map((item) => {
          completedTodos.push(item);
        });
    });

    return (
      <Row gutter={10}>
        <Col span={24}>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Регламент" key="1">
              <EntityTaskItems entityTaskItems={entityTaskItems} />
            </TabPane>
            <TabPane
              tab={`Чек-лист (${completedTodos.length}/${todos.length})`}
              key="2"
            >
              <Todos
                isTodoLoading={isTodoLoading}
                entityTaskTodos={entityTaskTodos}
                onSaveComment={onEntityTaskTodoSaveComment}
                onCompleted={onEntityTaskTodoCompleted}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    );
  }
}

TasksTodos.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default TasksTodos;
