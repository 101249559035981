/// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";

import Dashboard from "../Dashboard";
import MainLayout from "../Layout";
import NotFound from "../../components/NotFound";
import Login from "../Login";
import Recovery from "../Recovery";
import Reset from "../Reset";
import Confirm from "../Confirm";

import Tickets from "../Tickets/List";
import Ticket from "../Tickets/Form";

import Entities from "../Entities/List";
import Entity from "../Entities/Form";

import Employees from "../Employees/List";
import Employee from "../Employees/Form";

import EntityTasks from "../EntityTasks/List";
import EntityTask from "../EntityTasks/Form";

import Orders from "../Orders/List";
import Order from "../Orders/Form";

import OrderTasks from "../OrderTasks/List";
import OrderTask from "../OrderTasks/Form";

// tasks
import Tasks from "../Tasks/List";
import Task from "../Tasks/Form";

import Organisations from "../Organisations/List";
import Organisation from "../Organisations/Form";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.dispatch(Actions.authenticate());
    } else {
      this.props.dispatch(Actions.unauthenticate());
    }
  }

  renderRoutes = (isAuthenticated) => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/recovery" component={Recovery} />
          <Route path="/auth/reset/:token" component={Reset} />
          <Route path="/confirm/:token" component={Confirm} />
          <MainLayout>
            <Switch>
              <PrivateRoute
                isAuthenticated
                path="/"
                exact
                component={Dashboard}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets"
                exact
                component={Tickets}
              />

              <PrivateRoute
                isAuthenticated
                path="/tickets/:id/edit"
                exact
                component={Ticket}
              />
ß
              <PrivateRoute
                isAuthenticated
                path="/entities"
                exact
                component={Entities}
              />

              <PrivateRoute
                isAuthenticated
                path="/entities/:id/edit"
                exact
                component={Entity}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks"
                exact
                component={EntityTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/entity_tasks/:id/edit"
                exact
                component={EntityTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders"
                exact
                component={Orders}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/new"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/orders/:id/edit"
                exact
                component={Order}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks"
                exact
                component={OrderTasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/new"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/order_tasks/:id/edit"
                exact
                component={OrderTask}
              />

              <PrivateRoute
                isAuthenticated
                path="/tasks"
                exact
                component={Tasks}
              />

              <PrivateRoute
                isAuthenticated
                path="/tasks/:id/edit"
                exact
                component={Task}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations"
                exact
                component={Organisations}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations/new"
                exact
                component={Organisation}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations/:id/edit"
                exact
                component={Organisation}
              />

              <PrivateRoute
                isAuthenticated
                path="/employees"
                exact
                component={Employees}
              />

              <PrivateRoute
                isAuthenticated
                path="/employees/new"
                exact
                component={Employee}
              />

              <PrivateRoute
                isAuthenticated
                path="/employees/:id/edit"
                exact
                component={Employee}
              />

              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Switch>
      </Router>
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading
          ? null
          : this.renderRoutes(this.props.isAuthenticated)}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  currentAdmin: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoading: state.session.isLoading,
});
export default connect(mapStateToProps)(App);
