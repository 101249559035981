// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/orders";
import { Spin } from "antd";
import Form from "./Form";

class Order extends Component {
  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchOrder(this.props.match.params.id));
    }
  };

  onClose = () => {
    this.props.history.push(`/orders`);
  };

  onChangeStatus = (value) => {
    this.props.dispatch(Actions.onChangeStatus(this.props.match.params.id, value.id));
  };

  // comments
  onCreateComment = (value) => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchOrderComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file, attachmentType) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file, attachmentType))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  render() {
    const { currentEmployee, errors, isLoading, isCommentsLoading, isUploading, isAssigneesLoading, order } =
      this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        currentEmployee={currentEmployee}
        errors={errors}
        isLoading={isLoading}
        order={order}
        onClose={this.onClose}
        onChangeStatus={this.onChangeStatus}
        // comments
        isCommentsLoading={isCommentsLoading}
        onCreateComment={this.onCreateComment}
        // attachments
        isUploading={isUploading}
        onUploadAttachment={this.onUploadAttachment}
        onDeleteAttachment={this.onDeleteAttachment}
        // assignees
        isAssigneesLoading={isAssigneesLoading}
        onCreateAssignee={this.onCreateAssignee}
        onDeleteAssignee={this.onDeleteAssignee}
      />
    );
  }
}

Order.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  order: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentEmployee: state.session.currentEmployee,
  isLoading: state.orders.isLoading,
  errors: state.orders.errors,
  order: state.orders.order,
  // comments
  isCommentsLoading: state.orders.isCommentsLoading,
  // attachments
  isUploading: state.orders.isUploading,
  // assignees
  isAssigneesLoading: state.orders.isAssigneesLoading,
});

export default connect(mapStateToProps)(withRouter(Order));
