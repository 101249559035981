// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Descriptions, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

import Assignees from "../../../components/Assignees";
import User from "../../../components/User";
import Member from "../../../components/Member";

class Statuses extends Component {
  render() {
    const {
      ticket,
      user,
      member,
      onChangeStatus,
      // assignees
      isAssigneesLoading,
      isOrganisation,
      customer,
      company,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      onDeleteAssignee,
      onCreateAssignee,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Статус"}
              name="status"
              initialValue={ticket.status ? ticket.status : null}
              onChange={onChangeStatus}
            >
              <SelectFetch
                showSearch
                placeholder="Статус"
                onChange={onChangeStatus}
                selected={ticket.status ? ticket.status : null}
                url={`/tickets/${ticket.id}/statuses`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Descriptions column={1} bordered size="small">
              <Descriptions.Item label="Плановые дата и время" span={3}>
                {ticket.due_by ? moment(ticket.due_by).format("lll") : null}
              </Descriptions.Item>
              <Descriptions.Item label="Требуется замена">
                {ticket.is_replaced ? "да" : "нет"}
              </Descriptions.Item>
              <Descriptions.Item label="Приоритет" span={3}>
                {ticket.ticket_priority ? ticket.ticket_priority.name : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тип инцидента" span={3}>
                {ticket.incident_locale ? ticket.incident_locale : null}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Divider>Инициатор заявки</Divider>
            {user ? (
              <User user={user} />
            ) : member ? (
              <Member member={member} />
            ) : null}
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              customer={customer}
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От подрядчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="company"
              company={company}
              assignees={companyAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {};

export default Statuses;
