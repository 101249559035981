import update from "immutability-helper";

const initialState = {
  orderTasks: [],
  orderTask: {
    entity: { id: null },
    comments: [],
    assignees: [],
  },
  notices: [],
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    startDate: null,
    endDate: null,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
    status: null,
    taskTypes: null,
    users: null,
    members: null,
  },
  errors: false,
  isLoading: true,
  isCommentsLoading: false,
  isAssigneesLoading: false,
};

function loadLocalFilters(fetchParams) {
  let localFetchParams = null;
  const localFilters = localStorage.getItem("orderTasksFilters");
  if (localFilters) {
    localFetchParams = JSON.parse(localFilters);
  } else {
    localStorage.setItem("orderTasksFilters", JSON.stringify(fetchParams));
  }

  return localFetchParams;
}

export default function (state = initialState, action) {
  let localFetchParams = null;
  switch (action.type) {
    case "FETCH_ORDER_TASKS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDER_TASKS":
      return {
        ...state,
        orderTasks: action.response.data,
        meta: action.response.meta,
        notices: action.response.notices,
        isLoading: false,
      };
    case "FETCH_ORDER_TASK_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORDER_TASK":
      return {
        ...state,
        orderTask: action.response.data,
        isLoading: false,
      };

    case "REQUEST_ORDER_TASK_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ORDER_TASK_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ORDER_TASK_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ORDER_TASKS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { active: { $set: action.value } },
      });

    case "ORDER_TASKS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    // comments
    case "FETCH_ORDER_TASK_COMMENTS_REQUEST":
      return {
        ...state,
        isCommentsLoading: true,
      };
    case "FETCH_ORDER_TASK_COMMENTS":
      return update(state, {
        orderTask: { comments: { $set: action.response.data } },
        isCommentsLoading: { $set: false },
      });

    case "FETCH_ORDER_TASK_COMMENTS_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    case "POST_ORDER_TASK_COMMENT_REQUEST":
      return {
        ...state,
        errors: false,
        isCommentsLoading: true,
      };
    case "POST_ORDER_TASK_COMMENT":
      return {
        ...state,
        errors: false,
        isCommentsLoading: false,
      };

    case "POST_ORDER_TASK_COMMENT_FAILURE":
      return {
        ...state,
        errors: true,
        isCommentsLoading: false,
      };

    // assignees
    case "FETCH_ASSIGNEES_REQUEST":
      return {
        ...state,
        isAssigneesLoading: true,
      };
    case "FETCH_ASSIGNEES":
      return update(state, {
        orderTask: { assignees: { $set: action.response.data } },
        isAssigneesLoading: { $set: false },
      });

    case "FETCH_ASSIGNEES_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_REQUEST":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: true,
      };
    case "POST_ASSIGNEE":
      return {
        ...state,
        errors: false,
        isAssigneesLoading: false,
      };

    case "POST_ASSIGNEE_FAILURE":
      return {
        ...state,
        errors: true,
        isAssigneesLoading: false,
      };

    // attachments
    case "FETCH_ATTACHMENTS":
      return update(state, {
        orderTask: { attachments: { $set: action.response.data } },
        isUploading: { $set: false },
      });
    case "FETCH_ATTACHMENT_REQUEST":
      return {
        ...state,
        isUploading: true,
      };

    case "ATTACHMENT_UPLOAD":
      return update(state, {
        isUploading: { $set: true },
      });

    case "ATTACHMENT_UPLOAD_SUCCEED":
      return update(state, {
        orderTask: { attachments: { $set: action.response.data } },
        errors: { $set: false },
        isUploading: { $set: false },
      });

    case "ATTACHMENT_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploading: { $set: false },
      });

    case "ATTACHMENT_DELETE_REQUEST":
      return {
        ...state,
        errors: false,
        isUploading: true,
      };
    case "ATTACHMENT_DELETE_SUCCEED":
      return {
        ...state,
        errors: false,
        isUploading: false,
      };
    case "ATTACHMENT_DELETE_FAILED":
      return {
        ...state,
        errors: true,
        isUploading: false,
      };

    // statuses
    case "PATCH_ORDER_TASK_REQUEST_STATUS":
      return {
        ...state,
        errors: false,
      };
    case "PATCH_ORDER_TASK_STATUS":
      return update(state, {
        orderTask: {
          status: { $set: action.response.data.status },
          status_locale: { $set: action.response.data.status_locale },
          start: { $set: action.response.data.start },
          finish: { $set: action.response.data.finish },
        },
      });

    case "PATCH_ORDER_TASK_FAILURE_STATUS":
      return {
        ...state,
        errors: true,
      };

    // filters
    case "ORDER_TASKS_SET_FILTERS":
      localFetchParams = loadLocalFilters(state.fetchParams);
      if (localFetchParams) {
        localFetchParams.search = null;
        return {
          ...state,
          fetchParams: localFetchParams,
        };
      } else {
        return {
          ...state,
        };
      }

    case "ORDER_TASKS_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          status: { $set: null },
          users: { $set: null },
          members: { $set: null },
          orderUsers: { $set: null },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_STATUS":
      return update(state, {
        fetchParams: {
          status: { $set: action.value },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_TASK_TYPES":
      return update(state, {
        fetchParams: {
          taskTypes: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_PERIOD":
      return update(state, {
        fetchParams: {
          startDate: { $set: action.startDate },
          endDate: { $set: action.endDate },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_USERS":
      return update(state, {
        fetchParams: {
          users: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_MEMBERS":
      return update(state, {
        fetchParams: {
          members: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    case "ORDER_TASKS_FILTER_ORDER_USERS":
      return update(state, {
        fetchParams: {
          orderUsers: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    // notices
    case "POST_ORDER_TASK_MARK_ALL_AS_READ_REQUEST":
      return {
        ...state,
        errors: false,
      };
    case "POST_ORDER_TASK_MARK_ALL_AS_READ":
      return {
        ...state,
        errors: false,
        notices: action.response.data,
      };

    case "POST_ORDER_TASK_MARK_ALL_AS_READ_FAILURE":
      return {
        ...state,
        errors: true,
      };

    default:
      return state;
  }
}
