// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Descriptions } from "antd";

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }

  render() {
    const { entityTask } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions bordered>
              <Descriptions.Item label="Наименование" span={3}>
                <Link to={`/entities/${entityTask.entity.id}/edit`}>
                  {entityTask ? entityTask.entity.name : null}
                </Link>
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {entityTask ? entityTask.entity.address : null}
              </Descriptions.Item>
              <Descriptions.Item label="Телефоны" span={3}>
                {entityTask.customer ? entityTask.customer.phones : null}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entityTask: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func
};

export default General;
