// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Pagination,
  Row,
  Col,
  Input,
  Badge,
  Typography,
  Tag,
  Tooltip
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;


function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "processing";
  }
}

function serviceTag(item) {
  return (
    <Tooltip
      key={item.entity_service.id}
      placement="rightTop"
      title={
        item.entity_service ? item.entity_service.service.extra.category : null
      }
    >
      <Tag color="#108ee9">{item.entity_service.service.name}</Tag>
    </Tooltip>
  );
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      entityTasks,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterPeriod,
    } = this.props;

    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "number",
        render: (id, record) => (
          <Link to={`/entity_tasks/${id}/edit`}>{id}</Link>
        ),
        align: "center",
        width: "5%",
      },
      {
        title: "Статус",
        dataIndex: "state",
        render: (state, record) => <Badge status={statuses(record.status)} />,
        key: "state",
        align: "center",
        width: "5%",
      },
      {
        title: "План",
        dataIndex: "plan",
        render: (plan) =>
          plan ? (
            <span style={{ fontSize: 13 }}>{moment(plan).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "plan",
        align: "center",
        width: "10%",
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: (start) =>
          start ? (
            <span style={{ fontSize: 13 }}>{moment(start).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "10%",
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: (finish) =>
          finish ? (
            <span style={{ fontSize: 13 }}>{moment(finish).format("LLL")}</span>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%",
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "25%",
      },
      {
        title: "Раздел",
        dataIndex: "entity_task_items",
        key: "entity_task_items",
        render: (entity_task_items, record) => {
          return entity_task_items.map((item) => {
            return serviceTag(item);
          });
        },
        align: "center",
        width: "20%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%",
      },
    ];

    let filterButton = (
      <Button
        type={fetchParams.startDate || fetchParams.status ? "primary" : "ghost"}
        key="2"
        icon={<FilterOutlined />}
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={"Регламент"}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={entityTasks}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={this.onChangeFetchParamsLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
        />
      </PageHeader>
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default List;
