// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  List,
  Avatar,
  Popover,
  Tooltip,
  Button,
  Row,
  Col,
  Upload,
  Divider,
} from "antd";
import {
  PlusOutlined,
  CloseOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileUnknownOutlined,
} from "@ant-design/icons";

import moment from "moment";
import "moment/locale/ru";

function fileLink(url, name, target = "_self") {
  return (
    <a href={url} target={target} download={"123"}>
      {name}
    </a>
  );
}

function contentSet(item) {
  switch (item.content_type) {
    case "application/pdf":
      return {
        icon: <FilePdfOutlined />,
        color: "red",
        target: "_self",
      };
    case "image/png":
      return {
        icon: <FileImageOutlined />,
        color: "brown",
        target: "_blank",
      };
    case "image/jpeg":
      return {
        icon: <FileImageOutlined />,
        color: "brown",
        target: "_blank",
      };
    case "application/msword":
      return {
        icon: <FileWordOutlined />,
        color: "cornflowerblue",
        target: "_self",
      };
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return {
        icon: <FileWordOutlined />,
        color: "cornflowerblue",
        target: "_self",
      };
    case "application/vnd.ms-excel":
      return {
        icon: <FileExcelOutlined />,
        color: "green",
        target: "_self",
      };
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return {
        icon: <FileExcelOutlined />,
        color: "green",
        target: "_self",
      };
    default:
      return {
        icon: <FileUnknownOutlined />,
        color: "brown",
        target: "_self",
      };
  }
}

class Attachments extends Component {
  static defaultProps = {
    title: "Файлы",
    isReadOnly: false,
  };

  onUpload = ({ onSuccess, onError, file }) => {
    this.props.onUploadAttachment(file);
  };

  render() {
    const {
      currentUser,
      isUploading,
      attachments,
      onDeleteAttachment,
      title,
      isReadOnly,
    } = this.props;

    return (
      <React.Fragment>
        <Divider>
          {title}{" "}
          {isReadOnly ? null : (
            <Tooltip placement="bottom" title={"Загрузить файл"}>
              <Upload customRequest={this.onUpload} showUploadList={false}>
                <Button type="dashed" shape="circle" icon={<PlusOutlined/>} />
              </Upload>
            </Tooltip>
          )}
        </Divider>
        <Col span={24}>
          <List
            loading={isUploading}
            dataSource={attachments}
            renderItem={(item) => {
              let fileAttributes = contentSet(item);
              return (
                <List.Item
                  actions={[
                    <Tooltip placement="bottom" title={"Удалить"}>
                      <Popover
                        content={
                          <Button
                            type="danger"
                            style={{ width: "100%" }}
                            onClick={onDeleteAttachment.bind(this, item.id)}
                          >
                            Удалить
                          </Button>
                        }
                        title="Удаление файла"
                        trigger="click"
                      >
                        <Button type="dashed" shape="circle" icon={<CloseOutlined/>} />
                      </Popover>
                    </Tooltip>,
                  ]}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar
                        style={{
                          backgroundColor: "transparent",
                          color: fileAttributes.color,
                        }}
                        icon={fileAttributes.icon}
                        size="large"
                      />
                    }
                    title={fileLink(item.url, item.name, fileAttributes.target)}
                    description={`${moment(item.created_at).format("LLL")} - ${
                      item.file_size
                    }`}
                  />
                </List.Item>
              );
            }}
          />
        </Col>
        </React.Fragment>
    );
  }
}

Attachments.propTypes = {
  orderAttachments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Attachments;
