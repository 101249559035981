// @flow
import React, { Component } from "react";
import { Row, Col, Form, Card, Tabs, Button, Badge } from "antd";
import PropTypes from "prop-types";
import General from "./General";
import Comments from "./Comments";
import Documents from "../Documents";
import Statuses from "./Statuses";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class OrderTaskForm extends Component {
  render() {
    const {
      currentEmployee,
      errors,
      isLoading,
      orderTask,
      onClose,
      onChangeStatus,
      // comments
      isCommentsLoading,
      onCreateComment,
      // attachments
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
    } = this.props;

    const organisationAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const customerAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = orderTask.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2
              style={{ marginBottom: 0 }}
            >{`Задача по заказу (${orderTask.task_type_locale}) #${orderTask.number}`}</h2>
            <span style={{ fontSize: "12px" }}>
              <Badge
                status={statuses(orderTask.status ? orderTask.status.id : null)}
                text={orderTask.status ? orderTask.status.name : null}
              />
            </span>
          </div>
        }
        extra={[
          <Button key={1} onClick={onClose}>
            Закрыть
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <General
                entity={orderTask.entity}
                order={orderTask.order}
                customer={orderTask.customer}
                subject={orderTask.subject}
                description={orderTask.description}
              />

              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Комментарии" key="1">
                  <Comments
                    isCommentsLoading={isCommentsLoading}
                    errors={errors}
                    onCreateComment={onCreateComment}
                    comments={orderTask.comments}
                  />
                </TabPane>
              </Tabs>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Statuses
                orderTask={orderTask}
                currentEmployee={currentEmployee}
                user={orderTask.user}
                member={orderTask.member}
                // statuses
                onChangeStatus={onChangeStatus}
                // assignees
                isAssigneesLoading={isAssigneesLoading}
                isOrganisation={orderTask.is_organisation}
                customer={orderTask.customer}
                company={orderTask.company}
                organisationAssignees={organisationAssignees}
                customerAssignees={customerAssignees}
                companyAssignees={companyAssignees}
                onDeleteAssignee={onDeleteAssignee}
                onCreateAssignee={onCreateAssignee}
                // attachments
                attachments={orderTask.attachments}
                isUploading={isUploading}
                onUploadAttachment={onUploadAttachment}
                onDeleteAttachment={onDeleteAttachment}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

OrderTaskForm.propTypes = {
  projectType: PropTypes.object,
};

export default OrderTaskForm;
