// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const { Option } = Select;

class Filter extends Component {
  onChangeTaskUsers = (values) => {
    this.props.onChangeTaskUsers(values);
  };

  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeSupervisorUsers = (values) => {
    this.props.onChangeSupervisorUsers(values);
  };

  render() {
    const {
      visible,
      startCreatedDate,
      endCreatedDate,
      startDueByDate,
      endDueByDate,
      status,
      taskUsers,
      users,
      supervisorUsers,
      onClose,
      onChangePeriodCreated,
      onChangePeriodDueBy,
      onChangeStatus
    } = this.props;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (создание)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangePeriodCreated}
                  value={[
                    startCreatedDate
                      ? moment(startCreatedDate, "DD.MM.YYYY")
                      : null,
                    endCreatedDate
                      ? moment(endCreatedDate, "DD.MM.YYYY")
                      : null,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Выполнить до"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangePeriodDueBy}
                  value={[
                    startDueByDate
                      ? moment(startDueByDate, "DD.MM.YYYY")
                      : null,
                    endDueByDate ? moment(endDueByDate, "DD.MM.YYYY") : null,
                  ]}
                />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Статус"}>
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={onChangeStatus}
                  value={status ? status : []}
                >
                  <Option value={"1"}>новая</Option>
                  <Option value={"2"}>в работе</Option>
                  <Option value={"3"}>выполнено</Option>
                  <Option value={"4"}>отменена</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
