// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Checkbox, Button, Tooltip } from "antd";
import { FormOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ru";

class TodoItems extends Component {
  render() {
    const {
      isTodoLoading,
      entityTaskTodoIdx,
      entityTaskTodoItems,
      onCompleted,
      onOpenComment,
    } = this.props;

    return (
      <List
        loading={isTodoLoading}
        size="small"
        dataSource={entityTaskTodoItems}
        renderItem={(item, idx) => {
          let author = null;

          if (item.user) {
            author = item.user.name;
          }

          if (item.member) {
            author = item.member.name;
          }

          if (item.employee) {
            author = item.employee.name;
          }
          return (
            <List.Item
              actions={[
                <Checkbox
                  checked={item.completed_at ? true : false}
                  onChange={onCompleted.bind(
                    this,
                    item.entity_task_todo_id,
                    item.id,
                    entityTaskTodoIdx,
                    idx
                  )}
                />,
              ]}
            >
              <List.Item.Meta
                avatar={
                  <Tooltip placement="rightTop" title={"Комментарий"}>
                    <Button
                      onClick={onOpenComment.bind(
                        this,
                        item,
                        entityTaskTodoIdx,
                        idx
                      )}
                      type="dashed"
                      shape="circle"
                      icon={<FormOutlined />}
                    />
                  </Tooltip>
                }
                title={item.todo_item.name}
                description={
                  <React.Fragment>
                    <span>
                      {item.completed_at
                        ? `${author}, ${moment(item.completed_at).format(
                            "LLL"
                          )}`
                        : null}
                    </span>
                    <br />
                    <span>{item.comment}</span>
                  </React.Fragment>
                }
              />
            </List.Item>
          );
        }}
      />
    );
  }
}

TodoItems.propTypes = {
  onCompleted: PropTypes.func,
};

export default TodoItems;
