// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Button,
  Form,
  Card,
  Divider,
  Badge,
  Menu,
  Dropdown,
  Tabs,
} from "antd";
import Tasks from "../../../components/Tasks";

import Statuses from "./Statuses";
import General from "./General";
import Comments from "./Comments";
import TasksTodos from "./TasksTodos";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "finished":
      return "success";
    default:
      return "default";
  }
}

class EntityTaskForm extends Component {
  constructor(props) {
    super(props);
  }

  onSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onChangeStatus(values);
    });
  };

  callback = (key) => {
    if (key == "3") {
      this.props.fetchTasks();
    }
  };

  // actions
  onAction = (actionId) => {
    const { entityTask, actions, onOpenTaskForm } = this.props;
    let idx = actions.findIndex(function (o) {
      return o.id == actionId;
    });
    if (idx === -1) {
      return null;
    }
    onOpenTaskForm(actions[idx], entityTask.id);
  };

  ActionMenu = (actions) => {
    return (
      <Menu>
        {actions.map((action) => {
          return (
            <Menu.Item
              key={action.id}
              onClick={this.onAction.bind(this, action.id)}
            >
              {action.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  };

  render() {
    const {
      errors,
      isLoading,
      isCommentsLoading,
      entityTask,
      onClose,
      // statuses
      onChangeStatus,
      // comments
      onCreateComment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      // attachments
      isUploading,
      onDeleteAttachment,
      onUploadAttachment,
      // tasks
      isTasksLoading,
      tasks,
      // actions
      actions,
      // todos
      isTodoLoading,
      onEntityTaskTodoSaveComment,
      onEntityTaskTodoCompleted,
    } = this.props;

    let organisationAssignees = [];
    let supervisorAssignees = [];
    let customerAssignees = [];

    if (entityTask.assignees) {
      organisationAssignees = isLoading
        ? []
        : entityTask.assignees.filter(function (item) {
            return item.assignee_type == "organisation";
          });
    }

    if (entityTask.assignees) {
      supervisorAssignees = entityTask.assignees.filter(function (item) {
        return item.assignee_type == "supervisor";
      });
    }

    if (entityTask.assignees) {
      customerAssignees = entityTask.assignees.filter(function (item) {
        return item.assignee_type == "customer";
      });
    }

    const companyAssignees = entityTask.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    // actions
    let actionsButton = null;

    if (actions.length > 0) {
      actionsButton = (
        <Dropdown key={99} overlay={this.ActionMenu(actions)}>
          <Button icon={"more"} style={{ marginLeft: "8px" }} />
        </Dropdown>
      );
    }

    return (
      <Form layout={"vertical"} onSubmit={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <div>
              <h2
                style={{ marginBottom: 0 }}
              >{`Регламент #${entityTask.id}`}</h2>
              <span style={{ fontSize: "12px" }}>
                <Badge
                  status={statuses(entityTask.status)}
                  text={entityTask.status_locale}
                />
              </span>
            </div>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            actionsButton,
          ]}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <General
                entityTask={entityTask}
                start={entityTask.start}
                finish={entityTask.finish}
              />

              <TasksTodos
                isTodoLoading={isTodoLoading}
                entityTaskItems={entityTask.entity_task_items}
                isExistTodoTemplate={entityTask.entity.is_exist_todo_template}
                entityTaskTodos={entityTask.entity_task_todos}
                onEntityTaskTodoSaveComment={onEntityTaskTodoSaveComment}
                onEntityTaskTodoCompleted={onEntityTaskTodoCompleted}
              />

              <Divider>Комментарии</Divider>

              <Comments
                isCommentsLoading={isCommentsLoading}
                errors={errors}
                onCreateComment={onCreateComment}
                comments={entityTask.comments}
              />
            </Col>
            <Col xs={0} sm={0} md={0} lg={1} xl={1} />
            <Col span={10}>
              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Статусы" key="1">
                  <Statuses
                    organisationAssignees={organisationAssignees}
                    entityTaskId={entityTask.id}
                    status={entityTask.status}
                    plan={entityTask.plan}
                    start={entityTask.start}
                    finish={entityTask.finish}
                    // statuses
                    onChangeStatus={onChangeStatus}
                    // assignees
                    isAssigneesLoading={isAssigneesLoading}
                    // assignees
                    organisationAssignees={organisationAssignees}
                    supervisorAssignees={supervisorAssignees}
                    customerAssignees={customerAssignees}
                    companyAssignees={companyAssignees}
                    onCreateAssignee={onCreateAssignee}
                    onDeleteAssignee={onDeleteAssignee}
                    // attachments
                    isUploading={isUploading}
                    attachments={entityTask.attachments}
                    onDeleteAttachment={onDeleteAttachment}
                    onUploadAttachment={onUploadAttachment}
                  />
                </TabPane>
                <TabPane tab="Задачи" key="3">
                  <Tasks isLoading={isTasksLoading} tasks={tasks} />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

EntityTaskForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  entityTask: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default EntityTaskForm;
