import api from "../api";

const Actions = {};

Actions.fetchOrders = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORDERS_REQUEST" });
    let users = [];
    let employees = [];
    let params = getState().orders.fetchParams;
    if (params.users) {
      users = params.users.map(user => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.employees) {
      employees = params.employees.map(employee => {
        if (employee && employee.id) {
          return employee.id;
        }
      });
    }

    const data = {
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      starFinishDate: params.starFinishDate,
      endFinishDate: params.endFinishDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      users: users,
      employees: employees,
    };

    // console.log(params)
    return api
      .fetch("/orders", data)
      .then(response => {
        dispatch({ type: "FETCH_ORDERS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDERS_FAILURE", response });
      });
  };
};

Actions.fetchOrder = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_ORDER_REQUEST" });
    return api
      .fetch(`/orders/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_ORDER", response });
      })
      .catch(error => {
        if (error.response.status == 404) {
          window.location = "/notfound";
        }
        if (error.errors) {
          error.errors.map(error => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_FAILURE", error });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    const currentMember = getState().session.currentMember;
    dispatch({
      type: "ORDER_NEW",
      user: currentMember
    });
  };
};

Actions.onUpdate = (id, value) => {
  return (dispatch) => {
    dispatch({ type: "PATCH_ORDER_REQUEST" });
    const data = {
      order: {
        number: value
      }
    };
    api
      .patch(`/orders/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_ORDER", response });
        api.openNotification(
          "success",
          "Заказ",
          "Успешно обновлен!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification(
              "error",
              "Заказ",
              error
            );
          });
        }
        dispatch({ type: "PATCH_ORDER_FAILURE", response });
      });
  };
};

Actions.onCreate = (attachments = []) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_REQUEST" });
    const order = getState().orders.order;
    let orderAttachments = [];

    attachments.map((item) => {
      return orderAttachments.push({
        name: item.name,
        content_type: item.content_type,
        attachment: item.attachment,
      });
    });

    const data = {
      order: {
        status: 1,
        entity_id: order.entity ? order.entity.id : null,
        entity_service_id: order.entity_service
          ? order.entity_service.id
          : null,
        subject: order.subject,
        description: order.description,
        order_attachments_attributes: orderAttachments,
      }
    };

    return api
      .post(`/orders`, data)
      .then(response => {
        dispatch({ type: "POST_ORDER", response });
        api.openNotification(
          "success",
          "Заказ",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "POST_ORDER_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_ORDER_REQUEST" });
    return api
      .delete(`/orders/${id}`)
      .then(response => {
        api.openNotification(
          "success",
          "Заказ",
          "Успешно удален!"
        );
        dispatch({ type: "DELETE_ORDER", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({
          type: "DELETE_ORDER_FAILURE"
        });
      });
  };
};

// assignees
Actions.fetchAssignees = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/orders/${id}/order_assignees`)
      .then(response => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return dispatch => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/orders/${id}/order_assignees`, {
        order_assignee: {
          assignee_type: type,
          user_id: type == "organisation" ? assignee.id : null,
          member_id: type == "organisation" ? null : assignee.id
        }
      })
      .then(response => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (order_id, id) => {
  return dispatch => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST"
    });
    return api
      .delete(`/orders/${order_id}/order_assignees/${id}`)
      .then(response => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};

// proposals
Actions.fetchProposals = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ORDER_PROPOSALS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_proposals`)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_ORDER_PROPOSALS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_PROPOSALS_FAILURE", response });
      });
  };
};

Actions.onUploadProposal = (id, file) => {
  return dispatch => {
    dispatch({
      type: "ORDER_PROPOSAL_UPLOAD"
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    return api
      .postFormData(`/orders/${id}/order_proposals`, formData)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ORDER_PROPOSAL_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "ORDER_PROPOSAL_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onDeleteProposal = (order_id, id) => {
  return dispatch => {
    dispatch({
      type: "ORDER_PROPOSAL_DELETE_REQUEST"
    });
    return api
      .delete(`/orders/${order_id}/order_proposals/${id}`)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ORDER_PROPOSAL_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ORDER_PROPOSAL_DELETE_FAILED" });
      });
  };
};

// attachments
Actions.fetchAttachments = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_attachments`)
      .then(response => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file) => {
  return dispatch => {
    dispatch({
      type: "ATTACHMENT_UPLOAD"
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment", file);
    return api
      .postFormData(`/orders/${id}/order_attachments`, formData)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onDeleteAttachment = (entity_task_id, id) => {
  return dispatch => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST"
    });
    return api
      .delete(`/orders/${entity_task_id}/order_attachments/${id}`)
      .then(response => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// order_tasks
Actions.fetchOrderTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_ORDER_TASKS_REQUEST" });
    api
      .fetch(`/order_tasks`, { order_id: id })
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_ORDER_TASKS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_ORDER_TASKS_FAILURE", response });
      });
  };
};

Actions.onCreateOrderTask = (id, task) => {
  return dispatch => {
    dispatch({ type: "POST_ORDER_ORDER_TASK_REQUEST" });
    const assignees = task.assignees.map(item => {
      return {
        user_id: item.id
      };
    });

    const data = {
      order_task: {
        order_id: id,
        task_type: task.task_type,
        subject: task.subject,
        description: task.description,
        due_by: task.due_by,
        order_task_assignees_attributes: assignees
      }
    };
    return api
      .post(`/order_tasks`, data)
      .then(response => {
        dispatch({ type: "POST_ORDER_ORDER_TASK", response });
        api.openNotification("success", "Задача", "Успешно добавлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Задача", error);
          });
        }
        dispatch({ type: "POST_ORDER_ORDER_TASK_FAILURE", response });
      });
  };
};

// schedules
Actions.fetchSchedules = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ORDER_SCHEDULES_REQUEST" });
    api
      .fetch(`/order_schedules`, { order_id: id })
      .then(response => {
        dispatch({ type: "FETCH_ORDER_SCHEDULES", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORDER_SCHEDULES_FAILURE", response });
      });
  };
};

Actions.onUpdateSchedule = (id, schedule) => {
  return dispatch => {
    dispatch({ type: "PATCH_ORDER_SCHEDULE_REQUEST" });
    const data = {
      order_schedule: {
        order_id: id,
        schedule_type: schedule.schedule_type,
        name: schedule.name,
        start: schedule.start,
        finish: schedule.finish
      }
    };
    return api
      .patch(`/order_schedules/${schedule.id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_ORDER_SCHEDULE", response });
        api.openNotification("success", "График", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "PATCH_ORDER_SCHEDULE_FAILURE", response });
      });
  };
};

Actions.onCreateSchedule = (id, schedule) => {
  return dispatch => {
    dispatch({ type: "POST_ORDER_SCHEDULE_REQUEST" });
    const data = {
      order_schedule: {
        order_id: id,
        schedule_type: schedule.schedule_type,
        name: schedule.name,
        start: schedule.start,
        finish: schedule.finish
      }
    };
    return api
      .post(`/order_schedules`, data)
      .then(response => {
        dispatch({ type: "POST_ORDER_SCHEDULE", response });
        api.openNotification("success", "График", "Успешно добавлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "POST_ORDER_SCHEDULE_FAILURE", response });
      });
  };
};

Actions.onDeleteSchedule = id => {
  return dispatch => {
    dispatch({
      type: "DELETE_ORDER_SCHEDULE_REQUEST"
    });
    return api
      .delete(`/order_schedules/${id}`)
      .then(response => {
        api.openNotification("success", "График", "Успешно удален!");
        dispatch({ type: "DELETE_ORDER_SCHEDULE", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "График", error);
          });
        }
        dispatch({ type: "DELETE_ORDER_SCHEDULE_FAILURE" });
      });
  };
};

// comments
Actions.fetchOrderComments = id => {
  return dispatch => {
    dispatch({ type: "FETCH_ORDER_COMMENTS_REQUEST" });
    api
      .fetch(`/orders/${id}/order_comments`)
      .then(response => {
        dispatch({ type: "FETCH_ORDER_COMMENTS", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_ORDER_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORDER_COMMENT_REQUEST" });
    return api
      .post(`/orders/${id}/order_comments`, {
        order_comment: { comment: value }
      })
      .then(response => {
        dispatch({ type: "POST_ORDER_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_ORDER_COMMENT_FAILURE", response });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ORDER_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ORDER_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ORDER_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "ORDERS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

// tasks
Actions.fetchTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORDER_TASKS_REQUEST" });
    return api
      .fetch(`/orders/${id}/tasks`)
      .then((response) => {
        dispatch({ type: "FETCH_ORDER_TASKS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Задачи", error);
          });
        }
        dispatch({
          type: "FETCH_ORDER_TASKS_FAILURE",
        });
      });
  };
};

// filters
Actions.onSetFilters = () => {
  return dispatch => {
    dispatch({
      type: "ORDERS_SET_FILTERS"
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return dispatch => {
    dispatch({
      type: "ORDERS_CLEAR_FILTERS"
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = values => {
  return dispatch => {
    dispatch({
      type: "ORDERS_FILTER_STATUS",
      values: values
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriodCreated = (startDate, endDate) => {
  return dispatch => {
    dispatch({
      type: "ORDERS_FILTER_PERIOD_CREATED",
      startDate: startDate,
      endDate: endDate
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriodFinish = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "ORDERS_FILTER_PERIOD_FINISH",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = values => {
  return dispatch => {
    dispatch({
      type: "ORDERS_FILTER_USERS",
      values: values
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = values => {
  return dispatch => {
    dispatch({
      type: "ORDERS_FILTER_MEMBERS",
      values: values
    });
    return Promise.resolve();
  };
};

// notices
Actions.onClearNotices = () => {
  return dispatch => {
    dispatch({ type: "POST_ORDER_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/order_comment_marks/mark_all_as_read`)
      .then(response => {
        api.openNotification(
          "success",
          "Заказы",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_ORDER_MARK_ALL_AS_READ", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_ORDER_MARK_ALL_AS_READ_FAILURE"
        });
      });
  };
};




export default Actions;
