// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card } from "antd";


import Assignees from "../../../components/Assignees";

class Statuses extends Component {
  render() {
    const {
      customer,
      // assignees
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="supervisor"
              assignees={supervisorAssignees}
            />
          </Col>
        </Row>
        {/* <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Ответственные"}
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="organisation"
              assignees={organisationAssignees}
            />
          </Col>
        </Row> */}
        {/* <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От заказчика"}
              isReadOnly={true}
              isAssigneesLoading={false}
              assigneeType="customer"
              assignees={customerAssignees}
            />
          </Col>
        </Row> */}
      </Card>
    );
  }
}
Statuses.propTypes = {};

export default Statuses;
