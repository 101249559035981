// @flow
import React, { Component } from "react";
import { Row, Col, Form, Card, Tabs, Button, Space } from "antd";
import PropTypes from "prop-types";
import General from "./General";
import OrderTasks from "./OrderTasks";
import Comments from "./Comments";
import Documents from "../Documents";
import Statuses from "./Statuses";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class OrderForm extends Component {
  render() {
    const {
      currentEmployee,
      errors,
      isLoading,
      order,
      onClose,
      onChangeStatus,
      // order_tasks
      isOrderTasksLoading,
      onCreateOrderTask,
      // comments
      isCommentsLoading,
      onCreateComment,
      // attachments
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
    } = this.props;

    const organisationAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const customerAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = order.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const commonAttachments = order.attachments.filter(function (item) {
      return item.attachment_type == 1;
    });

    const actAttachments = order.attachments.filter(function (item) {
      return item.attachment_type == 2;
    });

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>
              {`Заказ #${order.number} от ${moment(order.created_at).format(
                "LLL"
              )}`}
            </h2>
          </div>
        }
        extra={[
          <Button key={1} onClick={onClose}>
            Закрыть
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <General order={order} />

              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Комментарии" key="1">
                  <Comments
                    isCommentsLoading={isCommentsLoading}
                    errors={errors}
                    onCreateComment={onCreateComment}
                    comments={order.comments}
                  />
                </TabPane>
                <TabPane tab="Задачи" key="2">
                  <OrderTasks
                    isOrderTasksLoading={isOrderTasksLoading}
                    errors={errors}
                    onCreateOrderTask={onCreateOrderTask}
                    orderTasks={order.order_tasks}
                  />
                </TabPane>
                <TabPane tab="Файлы" key="3">
                  <Documents
                    currentEmployee={currentEmployee}
                    title={""}
                    isUploading={isUploading}
                    attachments={commonAttachments}
                    onUpload={onUploadAttachment}
                    onDelete={onDeleteAttachment}
                  />
                </TabPane>
              </Tabs>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Statuses
                order={order}
                user={order.user}
                member={order.member}
                // statuses
                onChangeStatus={onChangeStatus}
                // assignees
                isAssigneesLoading={isAssigneesLoading}
                isOrganisation={order.is_organisation}
                customer={order.customer}
                company={order.company}
                organisationAssignees={organisationAssignees}
                supervisorAssignees={supervisorAssignees}
                customerAssignees={customerAssignees}
                companyAssignees={companyAssignees}
                onDeleteAssignee={onDeleteAssignee}
                onCreateAssignee={onCreateAssignee}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

OrderForm.propTypes = {
  projectType: PropTypes.object,
};

export default OrderForm;
