// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Descriptions, Divider } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectFetch from "../../../components/SelectFetch";

import Assignees from "../../../components/Assignees";
import User from "../../../components/User";
import Member from "../../../components/Member";

class Statuses extends Component {
  render() {
    const {
      entityTaskId,
      status,
      plan,
      start,
      finish,
      onChangeStatus,
      // assignees
      isAssigneesLoading,
      isOrganisation,
      customer,
      company,
      organisationAssignees,
      supervisorAssignees,
      customerAssignees,
      companyAssignees,
      onDeleteAssignee,
      onCreateAssignee,
      // attachments
      attachments,
      isUploading,
      onDeleteAttachment,
      onUploadAttachment,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Статус"}
              name="status"
              initialValue={status ? status : null}
              onChange={onChangeStatus}
            >
              <SelectFetch
                showSearch
                placeholder="Статус"
                onChange={onChangeStatus}
                selected={status ? status : null}
                url={`/entity_tasks/${entityTaskId}/statuses`}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
          <Descriptions bordered>
            <Descriptions.Item label="Плановые дата и время" span={3}>
              {plan ? moment(plan).format("lll") : null}
            </Descriptions.Item>
            <Descriptions.Item label="Начало выполнения работ" span={3}>
              {start ? moment(start).format("lll") : "не начались"}
            </Descriptions.Item>
            <Descriptions.Item label="Окончание выполнения работ" span={3}>
              {finish ? moment(finish).format("lll") : "не закончены"}
            </Descriptions.Item>
          </Descriptions>
          </Col>
        </Row>
        
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"Наблюдатели"}
              isReadOnly={true}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="supervisor"
              customer={customer}
              assignees={supervisorAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              title={"От подрядчика"}
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="company"
              company={company}
              assignees={companyAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {};

export default Statuses;
