// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { message } from "antd";
import Actions from "../../../actions/tasks";
import { Spin } from "antd";
import moment from "moment";
import "moment/locale/ru";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <Spin />;

class Task extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchTask(this.props.match.params.id));
  };



  onClose = () => {
    this.props.history.push(`/tasks`);
  };

  // header
  onChangeStatus = (value) => {
    this.props.dispatch(Actions.onChangeStatus(this.props.match.params.id, value));
  };

  

  // assignees
  onCreateAssignee = (value, type) => {
    this.props
      .dispatch(
        Actions.onCreateAssignee(this.props.match.params.id, value, type)
      )
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  onDeleteAssignee = (id) => {
    this.props
      .dispatch(Actions.onDeleteAssignee(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAssignees(this.props.match.params.id)
          );
        }
      });
  };

  // comments
  onCreateComment = (value) => {
    this.props
      .dispatch(Actions.onCreateComment(this.props.match.params.id, value))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchComments(this.props.match.params.id)
          );
        }
      });
  };

  // attachments
  onDeleteAttachment = (id) => {
    this.props
      .dispatch(Actions.onDeleteAttachment(this.props.match.params.id, id))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  onUploadAttachment = (file) => {
    this.props
      .dispatch(Actions.onUploadAttachment(this.props.match.params.id, file))
      .then(() => {
        if (!this.props.errors) {
          this.props.dispatch(
            Actions.fetchAttachments(this.props.match.params.id)
          );
        }
      });
  };

  // activities
  fetchActivities = () => {
    this.props.dispatch(Actions.fetchActivities(this.props.match.params.id));
  };

  // timing
  fetchTimings = () => {
    this.props.dispatch(Actions.fetchTimings(this.props.match.params.id));
  };

  onCreateTiming = () => {
    const time = moment();
    this.props.dispatch(
      Actions.onCreateTiming(this.props.match.params.id, time)
    );
  };

  onUpdateTiming = () => {
    const { taskTimings } = this.props;
    if (taskTimings.length > 0) {
      const time = moment();
      this.props
        .dispatch(
          Actions.onUpdateTiming(
            this.props.match.params.id,
            taskTimings[0].id,
            time
          )
        )
        .then(() => {
          message.warning("Не забудьте оставить комментарий к задаче!");
        });
    }
  };

  // todos
  onTaskTodoCompleted = (taskTodoId, id, taskTodoIdx, idx, e) => {
    this.props.dispatch(
      Actions.onTaskTodoCompleted(
        taskTodoId,
        id,
        taskTodoIdx,
        idx,
        e.target.checked ? moment() : null
      )
    );
  };

  // fields
  onChangeField = (name, value) => {
    this.props.dispatch(Actions.onChangeField(name, value));
  };

  render() {
    const {
      currentEmployee,
      isLoading,
      isCommentsLoading,
      isAttachmentsLoading,
      isTimingLoading,
      task,
      taskTimings,
      // todo
      isTodoLoading,
    } = this.props;
    return (
      <Suspense fallback={renderLoader()}>
        <Form
          currentEmployee={currentEmployee}
          isLoading={isLoading}
          task={task}
          onClose={this.onClose}
          onChangeStatus={this.onChangeStatus}
          // assignees
          onCreateAssignee={this.onCreateAssignee}
          onDeleteAssignee={this.onDeleteAssignee}
          // attachments
          isAttachmentsLoading={isAttachmentsLoading}
          onDeleteAttachment={this.onDeleteAttachment}
          onUploadAttachment={this.onUploadAttachment}
          // comments
          isCommentsLoading={isCommentsLoading}
          onCreateComment={this.onCreateComment}
          // timing
          taskTimings={taskTimings}
          isTimingLoading={isTimingLoading}
          fetchTimings={this.fetchTimings}
          onCreateTiming={this.onCreateTiming}
          onUpdateTiming={this.onUpdateTiming}
          // todos
          isTodoLoading={isTodoLoading}
          onTaskTodoCompleted={this.onTaskTodoCompleted}
          // fields
          onChangeField={this.onChangeField}
        />
      </Suspense>
    );
  }
}

Task.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  task: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentEmployee: state.session.currentEmployee,
  isLoading: state.tasks.isLoading,
  errors: state.tasks.errors,
  task: state.tasks.task,
  // comments
  isCommentsLoading: state.tasks.isCommentsLoading,
  // attachments
  isAttachmentLoading: state.tasks.isAttachmentLoading,
  // timing
  taskTimings: state.tasks.taskTimings,
  isTimingLoading: state.tasks.isTimingLoading,
  // todo
  isTodoLoading: state.tasks.isTodoLoading,
});

export default connect(mapStateToProps)(withRouter(Task));
