// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../actions/employees";
import EmployeesList from "./List";

class Employees extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchEmployees());
  };

  onDelete = id => {
    this.props
      .dispatch(Actions.onDelete(id))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  // filters
  onChangeFilterActive = value => {
    this.props
      .dispatch(Actions.onChangeFilterActive(value))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchEmployees()));
  };

  render() {
    const { isLoading, employees, meta, fetchParams } = this.props;
    return (
      <EmployeesList
        loading={isLoading}
        employees={employees}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSearch={this.onSearch}
        onChangeFilterActive={this.onChangeFilterActive}
        onSortBy={this.onSortBy}
      />
    );
  }
}

Employees.propTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.employees.isLoading,
  employees: state.employees.employees,
  meta: state.employees.meta,
  fetchParams: state.employees.fetchParams
});

export default connect(mapStateToProps)(Employees);
