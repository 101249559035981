// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const { Option, OptGroup } = Select;

class Filter extends Component {
  onChangeUsers = (values) => {
    this.props.onChangeUsers(values);
  };

  onChangeMembers = (values) => {
    this.props.onChangeMembers(values);
  };

  onChangeOrderUsers = (values) => {
    this.props.onChangeOrderUsers(values);
  };

  render() {
    const {
      visible,
      taskTypes,
      status,
      startDate,
      endDate,
      onClose,
      onChangeTaskType,
      onChangeStatus,
      onChangePeriod,
    } = this.props;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (план)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangePeriod}
                  value={[
                    startDate ? moment(startDate, "DD.MM.YYYY") : null,
                    endDate ? moment(endDate, "DD.MM.YYYY") : null,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Тип"}>
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Тип"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={onChangeTaskType}
                  value={taskTypes ? taskTypes : []}
                >
                  <Option value={1}>выезд на обследование</Option>
                  <Option value={2}>создание КП</Option>
                  <Option value={3}>согласование КП</Option>
                  <Option value={4}>закупка оборудования</Option>
                  <Option value={5}>выполнение работ</Option>
                  <Option value={6}>принятие работ</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Статус"}>
                <Select
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={onChangeStatus}
                  value={status}
                >
                  <Option key={null} value={null}>
                    все
                  </Option>
                  <OptGroup label="статусы">
                    <Option key={"status"} value={"pending"}>
                      ожидание
                    </Option>
                    <Option key={"status"} value={"processing"}>
                      выполняется
                    </Option>
                    <Option key={"status"} value={"finished"}>
                      выполнено
                    </Option>
                    <Option key={"status"} value={"canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="согласования КП">
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_failed"}
                    >
                      не согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_finished"}
                    >
                      согласовано
                    </Option>
                    <Option
                      key={"proposal_approving_status"}
                      value={"proposal_approving_canceled"}
                    >
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="закупка оборудования">
                    <Option key={"purchase_status"} value={"purchase_pending"}>
                      ожидание
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_processing"}
                    >
                      в закупке
                    </Option>
                    <Option
                      key={"purchase_status"}
                      value={"purchase_purchased"}
                    >
                      в наличии
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_finished"}>
                      доставлено на объект
                    </Option>
                    <Option key={"purchase_status"} value={"purchase_canceled"}>
                      отменено
                    </Option>
                  </OptGroup>
                  <OptGroup label="принятие работ">
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_pending"}
                    >
                      ожидание
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_accepted"}
                    >
                      принято
                    </Option>
                    <Option
                      key={"job_approving_status"}
                      value={"job_approving_canceled"}
                    >
                      отклонено с замечаниями
                    </Option>
                  </OptGroup>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
