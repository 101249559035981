// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DocumentsList from "./List";

class Documents extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { isLoading, attachments, onUpload, onDelete } = this.props;
    return (
      <DocumentsList
        isLoading={isLoading}
        documents={attachments}
        onUpload={onUpload}
        onDelete={onDelete}
      />
    );
  }
}

Documents.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  requestForProposal: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.tickets.isAttachmentLoading,
  errors: state.tickets.errors,
  task: state.tickets.project,
});

export default connect(mapStateToProps)(withRouter(Documents));
