// @flow
import React, { Component } from "react";
import { Row, Col, Form, Card, Tabs, Button, Space } from "antd";
import PropTypes from "prop-types";
import General from "./General";
import Comments from "./Comments";
import Documents from "../Documents";
import Statuses from "./Statuses";
import moment from "moment";
import "moment/locale/ru";

const { TabPane } = Tabs;

class TicketForm extends Component {
  render() {
    const {
      currentEmployee,
      errors,
      isLoading,
      ticket,
      onClose,
      onChangeStatus,
      // comments
      isCommentsLoading,
      onCreateComment,
      // attachments
      isUploading,
      onUploadAttachment,
      onDeleteAttachment,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
    } = this.props;

    const organisationAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "organisation";
    });

    const supervisorAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "supervisor";
    });

    const customerAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "customer";
    });

    const companyAssignees = ticket.assignees.filter(function (item) {
      return item.assignee_type == "company";
    });

    const commonAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 1;
    });

    const actAttachments = ticket.attachments.filter(function (item) {
      return item.attachment_type == 2;
    });

    return (
      <Card
        loading={isLoading}
        title={
          <div>
            <h2 style={{ marginBottom: 0 }}>
              {`Заявка #${ticket.number} от ${moment(ticket.created_at).format(
                "LLL"
              )}`}
            </h2>
          </div>
        }
        extra={[
          <Button key={1} onClick={onClose}>
            Закрыть
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={13} xl={13}>
              <General ticket={ticket} />

              <Tabs defaultActiveKey="1" onChange={this.callback}>
                <TabPane tab="Комментарии" key="1">
                  <Comments
                    isCommentsLoading={isCommentsLoading}
                    errors={errors}
                    onCreateComment={onCreateComment}
                    comments={ticket.comments}
                  />
                </TabPane>
                <TabPane tab="Файлы" key="2">
                  <Documents
                    currentEmployee={currentEmployee}
                    title={""}
                    isUploading={isUploading}
                    attachments={commonAttachments}
                    onUpload={onUploadAttachment}
                    onDelete={onDeleteAttachment}
                  />
                </TabPane>
              </Tabs>
            </Col>
            <Col xs={24} sm={24} md={24} lg={11} xl={11}>
              <Statuses
                ticket={ticket}
                user={ticket.user}
                member={ticket.member}
                // statuses
                onChangeStatus={onChangeStatus}
                // assignees
                isAssigneesLoading={isAssigneesLoading}
                isOrganisation={ticket.is_organisation}
                customer={ticket.customer}
                company={ticket.company}
                organisationAssignees={organisationAssignees}
                supervisorAssignees={supervisorAssignees}
                customerAssignees={customerAssignees}
                companyAssignees={companyAssignees}
                onDeleteAssignee={onDeleteAssignee}
                onCreateAssignee={onCreateAssignee}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

TicketForm.propTypes = {
  projectType: PropTypes.object,
};

export default TicketForm;
