// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Statuses from "./Statuses";

class EmployeeForm extends Component {
  onFinish = (values) => {
    this.props.onSave(values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const {
      onClose,
      isNew,
      isLoading,
      isUploadingAvatar,
      onUploadAvatar,
      employee,
      onChangeActive,
      onChangeRole,
      onChangeName,
      onChangeEmail,
      onChangePhone,
      onChangeTitle,
      // upload
      tempFile,
      onTempFileUpload,
    } = this.props;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Пользователь #${
            isNew ? " новый" : `${employee.name}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onFinish}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Header
                isNew={isNew}
                name={employee.name}
                title={employee.title}
                phone={employee.phone}
                email={employee.email}
                onChangeName={onChangeName}
                onChangeEmail={onChangeEmail}
                onChangePhone={onChangePhone}
                onChangeTitle={onChangeTitle}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8}>
              <Statuses
                isNew={isNew}
                isUploadingAvatar={isUploadingAvatar}
                onUploadAvatar={onUploadAvatar}
                isActive={employee.is_active}
                role={employee.role}
                onChangeActive={onChangeActive}
                onChangeRole={onChangeRole}
                // upload
                avatar={tempFile ? tempFile.attachment : employee.avatar}
                onTempFileUpload={onTempFileUpload}
              />
            </Col>
          </Row>
        </Form>
      </Card>
    );
  }
}

EmployeeForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  employee: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func,
};

export default EmployeeForm;
