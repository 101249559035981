import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
import {
  DashboardOutlined,
  FireOutlined,
  UserOutlined,
  BlockOutlined,
  CalendarOutlined,
  TagsOutlined,
  BarsOutlined,
  CheckSquareOutlined,
  AppstoreOutlined
} from "@ant-design/icons";


const SubMenu = Menu.SubMenu;

const commomRoutes = [
  {
    isActive: true,
    path: "/",
    exact: true,
    key: 10,
    name: "Рабочий стол",
    icon: <DashboardOutlined />,
    subroutes: [],
  },
  {
    isActive: true,
    path: "/tickets",
    key: 20,
    name: "Заявки",
    icon: <FireOutlined />,
    subroutes: [],
  },
  {
    isActive: true,
    path: "/entity_tasks",
    key: 30,
    name: "Регламент",
    icon: <CalendarOutlined />,
    subroutes: [],
  },
  // {
  //   isActive: true,
  //   path: null,
  //   key: 40,
  //   name: "Заказы",
  //   icon: <TagsOutlined />,
  //   subroutes: [
  //     {
  //       isActive: true,
  //       path: "/orders",
  //       key: 41,
  //       name: "Заказы",
  //       icon: <BlockOutlined />,
  //       subroutes: [],
  //     },
  //     {
  //       isActive: true,
  //       path: "/order_tasks",
  //       key: 42,
  //       name: "Задачи по заказам",
  //       icon: <BarsOutlined />,
  //       subroutes: [],
  //     },
  //   ],
  // },
  {
    isActive: true,
    path: "/tasks",
    key: 50,
    name: "Задачи",
    icon: <CheckSquareOutlined />,
    subroutes: [],
  },
  {
    isActive: true,
    path: "/employees",
    key: 60,
    name: "Пользователя",
    icon: <UserOutlined />,
    subroutes: [],
  },
  {
    isActive: true,
    path: "/entities",
    key: 70,
    name: "Объекты",
    icon: <AppstoreOutlined />,
    subroutes: [],
  },
  {
    isActive: true,
    path: "/organisations",
    key: 80,
    name: "Организации",
    icon: <BlockOutlined />,
    subroutes: [],
  },
 
];

function menuLeaf(item = {}) {
  if (item.subroutes.length > 0) {
    return (
      <SubMenu
        key={item.key}
        title={
          <span>
            {item.icon}
            <span>{item.name}</span>
          </span>
        }
      >
        {item.subroutes.map((child) => {
          return menuLeaf(child);
        })}
      </SubMenu>
    );
  } else {
    return (
      <Menu.Item key={item.key}>
        <Link to={item.path} className="nav-text">
          {item.icon}
          <span>{item.name}</span>
        </Link>
      </Menu.Item>
    );
  }
}

export default function MenuRoutes({ collapsed }) {
  const routes = commomRoutes;
  return (
    <Menu
      theme="dark"
      collapsed={collapsed}
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      {routes.map((item, i) => menuLeaf(item))}
    </Menu>
  );
}

