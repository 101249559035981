import api from "../api";

const Actions = {};

function setCurrentUser(dispatch, response) {
  dispatch({
    type: "AUTHENTICATION_SUCCESS",
    response: response,
  });
  localStorage.setItem("token", response.token);
  return Promise.resolve();
}

Actions.login = (data, push) => {
  return (dispatch) => {
    api
      .post("/employees/auth_employee", data)
      .then((response) => {
        setCurrentUser(dispatch, response).then(() => {
          push("/");
        });
      })
      .catch((response) => {
        api.openNotification("error", "Логин", response.errors[0]);
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};
Actions.recovery = (data, push) => {
  return (dispatch) => {
    dispatch({ type: "RECOVERY_REQUEST" });
    const params = {
      employee: {
        email: data.email,
      },
    };

    return api
      .post("/employees/recovery", params)
      .then((response) => {
        dispatch({ type: "RECOVERY_SUCCESS" });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Восстановление", error);
          });
        }
        dispatch({ type: "RECOVERY_FAILURE", response });
      });
  };
};

Actions.reset = (data, token, push) => {
  return (dispatch) => {
    const params = {
      employee: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        reset_password_token: token,
      },
    };

    return api
      .patch("/employees/reset", params)
      .then((response) => {
        api.openNotification("success", "Пароль", "Успешно изменен!");
        window.location = "/auth/login";
        // setCurrentUser(dispatch, response).then(() => { push("/"); });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};

Actions.confirm = (data, token, push) => {
  return (dispatch) => {
    const params = {
      confirmation: {
        password: data.password,
        password_confirmation: data.password_confirmation,
        confirmation_token: token,
      },
    };

    return api
      .patch("/employees/confirm", params)
      .then((response) => {
        window.location = "/auth/login";
        // setCurrentUser(dispatch, response).then(() => { push("/"); });
      })
      .catch((response) => {
        api.openNotification("error", "Подтверждение", response.errors[0]);
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
      });
  };
};

Actions.logout = (router) => {
  return (dispatch) => {
    return api.logout("/employees/logout").then(() => {
      localStorage.removeItem("token");
      dispatch({ type: "LOGOUT" });
      window.location = "/auth/login";
    });
  };
};

Actions.authenticate = () => {
  return (dispatch) => {
    dispatch({ type: "AUTHENTICATION_REQUEST" });
    return api
      .post("/employees/auth_employee_refresh")
      .then((response) => {
        setCurrentUser(dispatch, response);
      })
      .catch((response) => {
        dispatch({ type: "AUTHENTICATION_FAILURE", response });
        localStorage.removeItem("token");
        window.location = "/auth/login";
      });
  };
};

Actions.unauthenticate = () => {
  return (dispatch) => {
    dispatch({ type: "AUTHENTICATION_FAILURE" });
  };
};

export default Actions;
