// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;

const commonStatuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "working", text: "приступил к выполнению" },
  { value: "creating", text: "создание ДА" },
  { value: "approving", text: "согласование замены" },
  { value: "approved", text: "замена согласована" },
  { value: "replacing", text: "приступил к замене" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

const statuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "working", text: "приступил к выполнению" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

const replacedStatuses = [
  { value: "pending", text: "новая" },
  { value: "processing", text: "принято в работу" },
  { value: "creating", text: "создание ДА" },
  { value: "approving", text: "согласование замены" },
  { value: "approved", text: "замена согласована" },
  { value: "replacing", text: "приступил к замене" },
  { value: "paused", text: "приостановлена" },
  { value: "closed", text: "выполнена" },
  { value: "canceled", text: "отменена" },
];

class Filter extends Component {
  render() {
    const {
      visible,
      status,
      isReplaced,
      startDate,
      endDate,
      startFinishDate,
      endFinishDate,
      startCreatedDate,
      endCreatedDate,
      employees,
      onClose,
      onChangeStatus,
      onChangePeriod,
      onChangeFinishPeriod,
      onChangeCreatedPeriod,
      onChangeEmployees,
    } = this.props;

    const filtredStatuses =
      isReplaced == true
        ? replacedStatuses
        : isReplaced == false
        ? statuses
        : commonStatuses;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (создание)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangeCreatedPeriod}
                  value={[
                    startCreatedDate
                      ? moment(startCreatedDate, "DD.MM.YYYY")
                      : null,
                    endCreatedDate
                      ? moment(endCreatedDate, "DD.MM.YYYY")
                      : null,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (план)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangePeriod}
                  value={[
                    startDate ? moment(startDate, "DD.MM.YYYY") : null,
                    endDate ? moment(endDate, "DD.MM.YYYY") : null,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (выполнение)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangeFinishPeriod}
                  value={[
                    startFinishDate
                      ? moment(startFinishDate, "DD.MM.YYYY")
                      : null,
                    endFinishDate ? moment(endFinishDate, "DD.MM.YYYY") : null,
                  ]}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Статус"}>
                <Select
                  mode={"multiple"}
                  style={{ width: "100%" }}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={onChangeStatus}
                  value={status ? status : []}
                >
                  {filtredStatuses.map((status, i) => (
                    <Option key={i} value={status.value}>
                      {status.text}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Ответственные"}>
                <SelectMultiFetch
                  showSearch
                  placeholder="Ответственные"
                  onChange={onChangeEmployees}
                  selected={employees ? employees : []}
                  url={"/employees/search/?all='true'"}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
