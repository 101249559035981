// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Table, Button, PageHeader } from "antd";
import { Pagination } from "antd";
import { Row, Col, Icon, Input } from "antd";
import {
  FilterOutlined,
} from "@ant-design/icons";
import AssigneesCell from "../../../components/AssigneesCell";
import NoticeIcon from "../../../components/NoticeIcon";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";
import Filter from "./Filter";

const Search = Input.Search;

class OrderTasksList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  onItemClick = item => {
    this.props.history.push(`/order_tasks/${item.parent_id}/edit`);
  };

  onClearNotices = () => {
    this.props.onClearNotices();
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      orderTasks,
      meta,
      fetchParams,
      notices,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterStatus,
      onChangeFilterTaskType,
      onChangeFilterPeriod,
      onChangeFilterUsers,
      onChangeFilterMembers,
      onChangeFilterOrderUsers
    } = this.props;

    const dateFormat = "DD.MM.YYYY";
    const timeFormat = "hh:mm";

    const columns = [
      {
        title: "Статус",
        dataIndex: "status",
        key: "status",
        render: (status, record) => status.name,
        width: "10%",
        align: "center"
      },
      {
        title: (
          <div style={{ textAlign: "center" }}>
            <span>Номер</span>
            <br />
            <span>Тип</span>
            <br />
            <span>Заказ</span>
          </div>
        ),
        dataIndex: "number",
        key: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/order_tasks/${record.id}/edit`}>{number}</Link>
            </span>
            <span style={{ fontSize: 13, opacity: 0.7 }}>
              {record.task_type_locale}
            </span>
            <span>
              <Link to={`/orders/${record.order.id}/edit`}>
                {record.order.number}
              </Link>
            </span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        width: "10%",
        align: "center"
      },
      {
        title: "План",
        dataIndex: "due_by",
        key: "due_by",
        render: due_by =>
          due_by ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(due_by).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(due_by).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        align: "center",
        width: "10%"
      },
      {
        title: "Начало",
        dataIndex: "start",
        render: start =>
          start ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(start).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(start).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "start",
        align: "center",
        width: "10%"
      },
      {
        title: "Окончание",
        dataIndex: "finish",
        render: finish =>
          finish ? (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: 13 }}>
                {moment(finish).format(dateFormat)}
              </span>
              <span style={{ fontSize: 13, opacity: 0.7 }}>
                {moment(finish).format(timeFormat)}
              </span>
            </div>
          ) : null,
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "finish",
        align: "center",
        width: "10%"
      },
      {
        title: "Объект",
        dataIndex: "entity",
        key: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              {<Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>}
            </span>
            <span style={{ opacity: "0.7" }}>{entity.address}</span>
          </div>
        ),
        align: "center",
        width: "35%"
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        align: "center",
        width: "15%"
      }
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.taskTypes ||
          fetchParams.users ||
          fetchParams.members ||
          fetchParams.orderUsers
            ? "primary"
            : "ghost"
        }
        key="2"
        icon={<FilterOutlined />}
        onClick={this.onFilterToggle}
      />
    );

    const unread =  notices.filter(function(item) {
      return item.read == false;
    });

    const noticeIcon = (
      <NoticeIcon
        key="5"
        count={unread.length}
        onItemClick={this.onItemClick}
        list={notices}
        showClear
        onClear={this.onClearNotices}
        emptyText={"новых комментариев нет"}
      />
    );

    const faqButton = (
      <Button
        type={"ghost"}
        key="7"
        icon="question"
        onClick={this.props.onFaqToggle}
      />
    );

    return (
      <PageHeader
        className="page-header-with-notices"
        onBack={() => window.history.back()}
        title={`Задачи по заказам`}
        subTitle={
          fetchParams.startDate && fetchParams.endDate
            ? `c ${fetchParams.startDate} по ${fetchParams.endDate}`
            : null
        }
        extra={[
          // faqButton,
          // noticeIcon,
          <Search
            allowClear
            key="1"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={orderTasks}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          users={fetchParams.users}
          members={fetchParams.members}
          orderUsers={fetchParams.orderUsers}
          status={fetchParams.status}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          taskTypes={fetchParams.taskTypes}
          onClose={this.onFilterToggle}
          onClearFilters={onClearFilters}
          onChangeStatus={onChangeFilterStatus}
          onChangeTaskType={onChangeFilterTaskType}
          onChangePeriod={onChangeFilterPeriod}
          onChangeUsers={onChangeFilterUsers}
          onChangeMembers={onChangeFilterMembers}
          onChangeOrderUsers={onChangeFilterOrderUsers}
        />
      </PageHeader>
    );
  }
}

OrderTasksList.propTypes = {
  orderTasks: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object
};

export default withRouter(OrderTasksList);
