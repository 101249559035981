// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/employees";
import LoadingSpin from "../../../components/LoadingSpin";
import Form from "./Form";

class Employee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      tempFile: null,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchEmployee(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    const { tempFile } = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id, tempFile ? tempFile : null));
    } else {
      this.props.dispatch(Actions.onCreate(tempFile ? tempFile : null)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/employees/${this.props.employee.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/employees`);
  };

  onChangeActive = (e) => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onChangeRole = (value) => {
    this.props.dispatch(Actions.onChangeRole(value));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDepartment = (value) => {
    this.props.dispatch(Actions.onChangeDepartment(value));
  };

  onChangeEmail = (e) => {
    this.props.dispatch(Actions.onChangeEmail(e.target.value));
  };

  onChangeTitle = (e) => {
    this.props.dispatch(Actions.onChangeTitle(e.target.value));
  };

  onChangePhone = (e) => {
    this.props.dispatch(Actions.onChangePhone(e.target.value));
  };

  onUploadAvatar = (file) => {
    this.props.dispatch(Actions.onUploadAvatar(this.props.employee.id, file));
  };

  onTempFileUpload = (avatar) => {
    this.setState({
      tempFile: update(this.state.tempFile, {
        $set: avatar,
      }),
    });
  };


  render() {
    const { tempFile } = this.state;
    const { isLoading, isUploadingAvatar, errors, employee } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isNew={this.state.isNew}
        employee={employee}
        onSave={this.onSave}
        onClose={this.onClose}
        isUploadingAvatar={isUploadingAvatar}
        onUploadAvatar={this.onUploadAvatar}
        onChangeActive={this.onChangeActive}
        onChangeRole={this.onChangeRole}
        onChangeName={this.onChangeName}
        onChangeEmail={this.onChangeEmail}
        onChangeTitle={this.onChangeTitle}
        onChangePhone={this.onChangePhone}
        // upload
        tempFile={tempFile}
        onTempFileUpload={this.onTempFileUpload}
      />
    );
  }
}

Employee.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  employee: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.employees.isLoading,
  isUploadingAvatar: state.employees.isUploadingAvatar,
  errors: state.employees.errors,
  employee: state.employees.employee,
});

export default connect(mapStateToProps)(withRouter(Employee));
