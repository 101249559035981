// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Col, Row, Descriptions } from "antd";

class General extends Component {
  render() {
    const {
      entity,
      order,
      customer,
      subject,
      description,
      orderTask,
      onChangeSubject,
      onChangeDescription,
    } = this.props;

    return (
      <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
        <Col span={24}>
          <Row gutter={16}>
            <Descriptions bordered>
              <Descriptions.Item label="Заказ" span={3}>
                {order ? (
                  <span>
                    <Link
                      to={`/orders/${order.id}/edit`}
                    >{`Заказ #${order.number} `}</Link>
                    {order.subject}
                  </span>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Объект" span={3}>
                {entity ? (
                  <Link to={`/entities/${entity.id}/edit`}>{entity.name}</Link>
                ) : null}
              </Descriptions.Item>
              <Descriptions.Item label="Адрес" span={3}>
                {entity.address ? entity.address : null}
              </Descriptions.Item>
              <Descriptions.Item label="Телефоны" span={3}>
                {customer ? customer.phones : null}
              </Descriptions.Item>
              <Descriptions.Item label="Тема" span={3}>
                {subject}
              </Descriptions.Item>
              <Descriptions.Item label="Описание" span={3}>
                {description}
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
