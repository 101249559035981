// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Table, Input, Avatar, Button, Tooltip } from "antd";
import update from "immutability-helper";
import { Row, Col } from "antd";

import moment from "moment";
import "moment/locale/ru";

function periodicities(periodicity) {
  switch (periodicity) {
    case 0:
      return "нет";
    case 1:
      return "месяц";
    case 3:
      return "квартал";
    case 6:
      return "полугодие";
    default:
      return "нет";
  }
}

class Items extends Component {
  render() {
    const { entityServices } = this.props;

    const columns = [
      {
        title: "Раздел",
        dataIndex: "service",
        render: (service, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{record.service ? record.service.name : null}</span>
            <span style={{ opacity: "0.7" }}>
              {record.service ? record.service.extra.category : null}
            </span>
          </div>
        ),
        align: "left",
        width: "45%"
      },
      {
        title: "Периодичность",
        dataIndex: "periodicity",
        render: periodicity => periodicities(periodicity),
        width: "20%",
        align: "center"
      },
      {
        title: "Следующая дата регламента",
        dataIndex: "start",
        render: start => moment(start).format("LLL"),
        width: "35%",
        align: "center"
      }
    ];

    return (
      <div>
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col span={24}>
            <Table
              size={"middle"}
              bordered
              pagination={false}
              columns={columns}
              dataSource={entityServices}
              rowKey="id"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Items.propTypes = {
  entityServices: PropTypes.arrayOf(PropTypes.object),
};
export default Items;
