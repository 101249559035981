// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Table,
  Button,
  PageHeader,
  Pagination,
  Row,
  Col,
  Input,
  Badge,
  Typography,
  Tag
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import AssigneesCell from "../../../components/AssigneesCell";
import moment from "moment";
import "moment/locale/ru";

import Filter from "./Filter";

const Search = Input.Search;
const { Paragraph } = Typography;

function statuses(status) {
  switch (status) {
    case "pending":
      return "error";
    case "processing":
      return "processing";
    case "closed":
      return "success";
    default:
      return "processing";
  }
}

function serviceTag(service) {
  return (
    <Tag key={service.id} color="#108ee9">
      {service.name}
    </Tag>
  );
}

class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilterVisible: false,
    };
  }

  onFilterToggle = () => {
    this.setState({ isFilterVisible: !this.state.isFilterVisible });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { isFilterVisible } = this.state;
    const {
      loading,
      tickets,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      // filters
      onClearFilters,
      onChangeFilterPeriod,
      onChangeFilterFinishPeriod,
      onChangeFilterCreatedPeriod,
      onChangeFilterStatus,
      onChangeFilterEmployees
    } = this.props;

    const columns = [
      {
        title: "Статус Приоритет Замена",
        dataIndex: "status",
        render: (status, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Badge
                status={statuses(record.status.id)}
                text={record.status.name}
              />
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.ticket_priority ? record.ticket_priority.name : null}
            </span>
            <span style={{ opacity: "0.7", fontSize: "12px" }}>
              {record.is_replaced ? "замена" : null}
            </span>
          </div>
        ),
        key: "status",
        responsive: ['md'],
        align: "center",
        width: "10%",
      },
      {
        title: "Номер",
        dataIndex: "number",
        render: (number, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/tickets/${record.id}/edit`}>{number}</Link>
            </span>
          </div>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "number",
        align: "center",
        width: "10%",
      },
      {
        title: "План",
        dataIndex: "due_by",
        render: (due_by) => (
          <span style={{ fontSize: 12 }}>{moment(due_by).format("LLL")}</span>
        ),
        sorter: true,
        sortDirections: ["ascend", "descend"],
        key: "due_by",
        align: "center",
        width: "10%",
      },
      {
        title: "Выполнение",
        dataIndex: "finish",
        render: (finish) => (finish ? moment(finish).format("L") : null),
        key: "finish",
        responsive: ['md'],
        align: "center",
        width: "10%",
      },
      {
        title: "Объект",
        dataIndex: "entity",
        render: (entity, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Link to={`/entities/${entity.id}/edit`}>
                {entity ? entity.name : null}
              </Link>
            </span>
            <span style={{ opacity: "0.7" }}>
              {record.entity ? record.entity.address : null}
            </span>
          </div>
        ),
        align: "center",
        width: "20%",
      },
      {
        title: "Тема / Раздел",
        dataIndex: "subject",
        render: (subject, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>
              <Paragraph ellipsis={{ rows: 3, expandable: true }}>
                {subject}
              </Paragraph>
            </span>
            <span>
              {record.category
                ? serviceTag(record.category)
                : record.entity_service
                ? serviceTag(record.entity_service)
                : null}
            </span>
          </div>
        ),
        responsive: ['md'],
        align: "left",
        width: "23%",
      },
      {
        title: "Ответственный",
        dataIndex: "assignees",
        render: (assignees, record) => <AssigneesCell assignees={assignees} />,
        responsive: ['md'],
        align: "center",
        width: "18%",
      },
    ];

    let filterButton = (
      <Button
        type={
          fetchParams.startFinishDate ||
          fetchParams.startCreatedDate ||
          fetchParams.startDate ||
          fetchParams.status ||
          fetchParams.employees
            ? "primary"
            : "ghost"
        }
        key="filterButton"
        icon={<FilterOutlined />}
        onClick={this.onFilterToggle}
      />
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={"Заявки"}
        extra={[
          <Search
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          filterButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tickets}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      >
        <Filter
          visible={isFilterVisible}
          status={fetchParams.status}
          isReplaced={fetchParams.is_replaced}
          startDate={fetchParams.startDate}
          endDate={fetchParams.endDate}
          startFinishDate={fetchParams.startFinishDate}
          endFinishDate={fetchParams.endFinishDate}
          startCreatedDate={fetchParams.startCreatedDate}
          endCreatedDate={fetchParams.endCreatedDate}
          employees={fetchParams.employees}
          onClose={this.onFilterToggle}
          onChangeStatus={onChangeFilterStatus}
          onChangePeriod={onChangeFilterPeriod}
          onChangeFinishPeriod={onChangeFilterFinishPeriod}
          onChangeCreatedPeriod={onChangeFilterCreatedPeriod}
          onChangeEmployees={onChangeFilterEmployees}
        />
      </PageHeader>
    );
  }
}

List.propTypes = {
  payments: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default List;
