import api from "../api";
import { saveAs } from "file-saver";

const Actions = {};

Actions.fetchTickets = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_TICKETS_REQUEST" });
    let users = [];
    let employees = [];
    const params = getState().tickets.fetchParams;
    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }
    if (params.employees) {
      employees = params.employees.map((employee) => {
        if (employee && employee.id) {
          return employee.id;
        }
      });
    }

    const data = {
      startDate: params.startDate,
      endDate: params.endDate,
      startCreatedDate: params.startCreatedDate,
      endCreatedDate: params.endCreatedDate,
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      status: params.status,
      is_replaced: params.is_replaced,
      is_ordered: params.is_ordered,
      is_rated: params.is_rated,
      users: users,
      employees: employees,
      priorities: params.priorities,
    };

    return api
      .fetch("/tickets", data)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_TICKETS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_TICKETS_FAILURE", response });
      });
  };
};

Actions.fetchTicket = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TICKET_REQUEST" });
    return api
      .fetch(`/tickets/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_TICKET", response });
      })
      .catch((error) => {
        if (error.response.status == 404) {
          window.location = "/notfound";
        }
        if (error.errors) {
          error.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "FETCH_TICKET_FAILURE", error });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_TICKET_REQUEST" });
    return api
      .delete(`/tickets/${id}`)
      .then((response) => {
        api.openNotification("success", "Заявка", "Успешно удалена!");
        dispatch({ type: "DELETE_TICKET", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "DELETE_TICKET_FAILURE",
        });
      });
  };
};
// comments
Actions.fetchTicketComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TICKET_COMMENTS_REQUEST" });
    api
      .fetch(`/tickets/${id}/ticket_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_TICKET_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({ type: "FETCH_TICKET_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_TICKET_COMMENT_REQUEST" });
    return api
      .post(`/tickets/${id}/ticket_comments`, {
        ticket_comment: { comment: value },
      })
      .then((response) => {
        dispatch({ type: "POST_TICKET_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_TICKET_COMMENT_FAILURE", response });
      });
  };
};

// documents
Actions.fetchAttachments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ATTACHMENTS_REQUEST" });
    api
      .fetch(`/tickets/${id}/ticket_attachments`)
      .then((response) => {
        // console.log(response)
        dispatch({ type: "FETCH_ATTACHMENTS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ATTACHMENTS_FAILURE", response });
      });
  };
};

Actions.onUploadAttachment = (id, file, attachmentType) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_UPLOAD",
    });
    // console.log(file);
    const formData = new FormData();

    formData.append("content_type", file.type);
    formData.append("attachment_type", attachmentType);
    formData.append("attachment", file);
    return api
      .postFormData(`/tickets/${id}/ticket_attachments`, formData)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно загружен!");
        dispatch({ type: "ATTACHMENT_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ATTACHMENT_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

Actions.onDeleteAttachment = (ticket_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ATTACHMENT_DELETE_REQUEST",
    });
    return api
      .delete(`/tickets/${ticket_id}/ticket_attachments/${id}`)
      .then((response) => {
        api.openNotification("success", "Файл", "Успешно удален!");
        dispatch({ type: "ATTACHMENT_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Файл", error);
          });
        }
        dispatch({ type: "ATTACHMENT_DELETE_FAILED" });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ASSIGNEES_REQUEST" });
    api
      .fetch(`/tickets/${id}/ticket_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_ASSIGNEE_REQUEST" });
    return api
      .post(`/tickets/${id}/ticket_assignees`, {
        ticket_assignee: {
          assignee_type: type,
          employee_id:
            type == "company"  ? assignee.id : null,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (ticket_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(`/tickets/${ticket_id}/ticket_assignees/${id}`)
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "ASSIGNEE_DELETE_FAILED" });
      });
  };
};
// statuses


Actions.onChangeStatus = (id, status) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TICKET_REQUEST_STATUS" });
    const data = { status: status };

    return api
      .patch(`/tickets/${id}/status`, data)
      .then((response) => {
        api.openNotification("success", "Заявка", "Статус изменен!");
        dispatch({ type: "PATCH_TICKET_STATUS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "PATCH_TICKET_FAILURE_STATUS",
        });
      });
  };
};



// activities
Actions.fetchRequestForTicketActivities = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TICKET_ACTIVITIES_REQUEST" });
    return api
      .fetch(`/tickets/${id}/ticket_activities`)
      .then((response) => {
        dispatch({ type: "FETCH_TICKET_ACTIVITIES", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_TICKET_ACTIVITIES_FAILURE",
        });
      });
  };
};

// tasks
Actions.fetchTasks = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_TICKET_TASKS_REQUEST" });
    return api
      .fetch(`/tickets/${id}/tasks`)
      .then((response) => {
        dispatch({ type: "FETCH_TICKET_TASKS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "FETCH_TICKET_TASKS_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TICKET_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TICKET_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TICKET_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TICKET_FILTER_ASSIGNEE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_TICKET_FILTER_STATUS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeAssignee = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKET_CHANGE_ASSIGNEE",
      value: value,
    });
  };
};


// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatus = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_STATUS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterReplaced = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_REPLACED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterOrdered = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_ORDERED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterCreatedPeriod = (startDate, endDate) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_CREATED_PERIOD",
      startDate: startDate,
      endDate: endDate,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterEmployees = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_EMPLOYEES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterPriorities = (values) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_PRIORITIES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterRated = (value) => {
  return (dispatch) => {
    dispatch({
      type: "TICKETS_FILTER_RATED",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.downloadReport = (params, url = "", filename = "") => {
  let users = [];
  let members = [];
  if (params.users) {
    users = params.users.map((user) => {
      if (user && user.id) {
        return user.id;
      }
    });
  }
  if (params.members) {
    members = params.members.map((member) => {
      if (member && member.id) {
        return member.id;
      }
    });
  }

  const data = {
    startDate: params.startDate,
    endDate: params.endDate,
    search: params.search,
    sort: params.sort,
    direction: params.direction,
    page: params.page,
    limit: params.limit,
    status: params.status,
    replaced_status: params.replaced_status,
    users: users,
    members: members,
    priorities: params.priorities,
  };

  api
    .fetchBlob(`/${url}`, data)
    .then((response) => {
      //console.log(response)
      saveAs(response, `${filename}`);
    })
    .catch((response) => {
      console.log(response.error);
    });
};

// notices
Actions.onClearNotices = () => {
  return (dispatch) => {
    dispatch({ type: "POST_TICKET_MARK_ALL_AS_READ_REQUEST" });

    return api
      .post(`/ticket_comment_marks/mark_all_as_read`)
      .then((response) => {
        api.openNotification(
          "success",
          "Заявки",
          "Все комментарии отмечены как прочитанные!"
        );
        dispatch({ type: "POST_TICKET_MARK_ALL_AS_READ", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Заявка", error);
          });
        }
        dispatch({
          type: "POST_TICKET_MARK_ALL_AS_READ_FAILURE",
        });
      });
  };
};

// rates
Actions.onCreateTicketRate = (rate) => {
  return (dispatch) => {
    dispatch({ type: "POST_TICKET_RATE_REQUEST" });
    const params = {
      ticket_rate: {
        ticket_id: rate.ticket_id,
        rate: rate.rate,
        comment: rate.comment,
      },
    };
    return api
      .post(`/ticket_rates`, params)
      .then((response) => {
        api.openNotification("success", "Заявки", "Спасибо за оценку!");
        dispatch({ type: "POST_TICKET_RATE", response, id: rate.ticket_id });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Рейтинг", error);
          });
        }
        dispatch({ type: "POST_TICKET_RATE_FAILURE", response });
      });
  };
};

export default Actions;
