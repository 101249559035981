// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, DatePicker, Select, Form } from "antd";
import moment from "moment";
import "moment/locale/ru";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { RangePicker } = DatePicker;
const Option = Select.Option;

class Filter extends Component {
  render() {
    const { visible, status, startDate, endDate, onClose, onChangePeriod } =
      this.props;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Form layout={"vertical"}>
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.Item label={"Период (план)"}>
                <RangePicker
                  showTime={false}
                  format={"DD.MM.YYYY"}
                  placeholder={["начало", "окончание"]}
                  style={{ width: "100%" }}
                  onChange={onChangePeriod}
                  value={[
                    startDate ? moment(startDate, "DD.MM.YYYY") : null,
                    endDate ? moment(endDate, "DD.MM.YYYY") : null,
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
